.buttonMultiSelect {
  position: absolute;
  z-index: 999999999;
  width: 32px;
  height: 32px;
  color: white;
  background: linear-gradient(270deg, #2962ff 0%, #0039cb 100%);
  padding: 5px;
  border-radius: 3px;
}

.picky {
  width: 32px !important;
}

.picky__dropdown [type='checkbox'] {
  opacity: 0;
  height: 1px;
  width: 1px;
}

.picky__dropdown .option:before {
  content: ' ';
  background-image: url('../../static/img/Icons/bitacheckboxblue.png');
  background-size: 17px 33px;
  height: 17px;
  width: 17px;
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 13px;
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: -2px;
}

.picky__dropdown .option.selected:before {
  content: ' ';
  height: 17px;
  width: 17px;
  display: inline-block;
  line-height: 19px;
  background-image: url('../../static/img/Icons/bitacheckbox.png');
  background-size: 17px 33px;
  background-position: 0 -17px;
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: -2px;
}

.picky__input {
  position: relative;
  z-index: 999999999999;
  opacity: 0;
  width: 32px;
  height: 32px;
}

.picky__input span {
  display: none;
}

.picky__dropdown {
  width: 200px !important;
  border: none !important;
  background: transparent !important;
  top: 33px !important;
  overflow: initial !important;
}

.picky__dropdown .option,
.picky__dropdown li {
  border: none !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #0039cb !important;
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
}

.picky__dropdown .option.selected,
.picky__dropdown li.selected {
  background-color: #748dff !important;
  color: white !important;
}
