.headerpaths {
  font-size: 21px;
  border-bottom: 1px solid #a7a7aa;
  padding: 10px 0 10px 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  svg {
    width: 40px;
  }
  span {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    margin-right: 0px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.headerpaths span:not(:first-child):not(:last-child)::after {
  color: #0039cb;
  content: ' ';
  margin-left: 18px;
  font-size: 30px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #0039cb;
  display: block;
  float: right;
  margin-top: 8px;
}

.closeModalButton {
  font-size: 30px;
  cursor: pointer;
  font-weight: bold;
  display: block;
  position: absolute;
  top: -20px;
  right: -10px;
}

.descriptionModal {
  font-size: 12px;
}
.contentModalSave {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  button {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
.contentModalSave button:nth-child(1) {
  color: #2962ff !important;
  background: #fff !important;
  width: 280px !important;
  margin-bottom: 16px;
}

.contentModalSave .bitabutton {
  color: #2962ff !important;
  background: #fff !important;
  width: 200px !important;
  margin-bottom: 16px;
}

.contentModalSave .bitabutton:nth-last-child(1),
.contentModalSave .bitabutton:nth-last-child(2) {
  color: #fff !important;
  width: 100% !important;
  height: 38px !important;
  margin-top: 70px !important;
  background: linear-gradient(270deg, #2962ff 0%, #0039cb 100%) !important;
}

.contentModalSave .bitabutton:nth-last-child(1):hover,
.contentModalSave .bitabutton:nth-last-child(2):hover {
  background: linear-gradient(270deg, #768fff 0%, #2962ff 100%) !important;
}
.contentModalSave .builderInputComponentContainer {
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
  div {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }
  input {
    width: 100%;
  }
  span {
    font-size: 12px;
    color: #2962ff;
    font-weight: 700;
  }
}
