.dropdownContainer {
  border-radius: 5px;
  background: #2a2d31 !important;
  margin-bottom: 0px !important;
  font-weight: 500 !important;
}

.barsearch__control {
  min-height: 35px !important;
  max-height: 35px !important;
  border-radius: 5px !important;
  background: #2a2d31 !important;
  color: white !important;
  border-width: 1px !important;
  border: 1px solid white;
  margin-bottom: 10px !important;
  font-size: 13px;
  cursor: text !important;
}

.barsearch__menu {
  min-width: 250px;
  font-weight: 500;
  margin-top: 5px !important;
  padding: 0px !important;
  border-radius: 5px !important;
  overflow: hidden;
  padding: 0px !important;
  border: 1px solid white;
  opacity: 1 !important;
  font-size: 13px;
}

.barsearch__value-container {
  padding: 0px 8px;
  border-radius: 5px;
  color: #2a2d31 !important;
  margin-top: 2px;
}

.barsearch__option:first-child {
  position: relative;
  top: -4px;
  background: #2a2d31;
  color: white;
}

.barsearch__placeholder {
  font-weight: 500 !important;
  top: 45% !important;
  color: white !important;
  white-space: nowrap;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.barsearch__single-value > div > div > div > {
  margin-right: 20px;
}

.barsearch__indicator {
  padding: 4px 2px !important;
  color: white !important;
  margin-top: -3px;
}

.barsearch__option:hover {
  background: white !important;
  color: #2a2d31 !important;
}
