.contentContainer {
  width: 86vw;
  height: auto;
  min-height: 35vh;
  padding-bottom: 50px;
}

.headerContainer {
  height: 9vh;
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #fff;
  align-items: center;
}

.closeModalButton {
  font-size: 30px;
  cursor: pointer !important;
  font-weight: bold;
  display: block;
  position: absolute;
  top: -15px;
  right: 0;
  padding: 20px;
}

.headerText {
  /* height: 28px; */
  /* width: 642px; */
  color: #ffffff;
  padding-left: 21px;
  /* font-family: "Neue Haas Grotesk Text Pro"; */
  font-size: 23px;
  letter-spacing: 3.72px;
  line-height: 28px;
}

.backtestDetailsContainer {
  padding: 25px;
  height: 15vh;
  display: flex;
  border-radius: 5px;
  background-color: #3b3f44;
  flex-wrap: wrap;
}

.detailsTitle {
  width: 100%;
  /* height: 19px; */
  /* width: 340px; */
  color: #ffffff;
  /* font-family: "Neue Haas Grotesk Text Pro"; */
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
}

.indexDataContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/*.baseContainer {
  width: 33.3%;
}*/

.baseTitle {
  /* height: 16px; */
  /* width: 340px; */
  color: #2962ff;
  /* font-family: "Neue Haas Grotesk Text Pro"; */
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2.26px;
  line-height: 18px;
}

.baseContentContainer {
  /* height: 36px; */
  /* width: 340px; */
  color: #ffffff;
  /* font-family: "Neue Haas Grotesk Text Pro"; */
  font-size: 14px;
  letter-spacing: 2.26px;
  line-height: 17px;
}

.backtestDataContainer {
  padding: 20px;
  border-radius: 5px;
  background-color: #3b3f44;
  margin-top: 15px;
}

.dataTitleContainer {
  /* height: 19px; */
  /* width: 340px; */
  color: #ffffff;
  /* font-family: "Neue Haas Grotesk Text Pro"; */
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
}

.dataDropdownContainer {
  display: flex;
  align-items: center;
}

.dataDropdownText {
  margin-right: 10px;
}

.dataChartTitle {
  height: 18px;
  //* width: 343px; */
  color: #2962ff;
  /* font-family: "Neue Haas Grotesk Text Pro"; */
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2.26px;
  line-height: 18px;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 98%;
  bottom: 0px;
  padding-top: 20px;
  padding-bottom: 0px;
}
