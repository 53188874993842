.modalContainer {
  padding: 10px;
  position: relative;
  width: 900px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.titleContainer {
  height: 70px;
  font-size: 18.4px;
  letter-spacing: 2.97px;
  line-height: 23px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.closeButton {
  position: absolute;
  right: 0;
  cursor: pointer;
  top: -10px;
  font-size: 30px;
  cursor: pointer;
  font-weight: bold;
}

.tabsContainer {
  padding: 10px;
}

.nameContainer {
  padding: 10px;
}

.lockContainer {
  padding: 10px;
}
.tabsButtonContainer {
  display: flex;
  justify-content: space-between;
}

.tabContentContainer {
  width: 100%;
  height: 100%;
  flex: 1;
}

.tabTitleButton {
  cursor: pointer;
  margin-right: 9px;
  height: 33px;
  padding: 14px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 1.48px;
  line-height: 20px;
  text-align: center;
  border-radius: 3.15px;
  font-weight: bold;
}

.tabTitleButtonActive {
  background: #2962ff;
}
.tabTitleButtonInactive {
  color: #c7c7c7;
  border-radius: 4.05px;
  background-color: #4b5158;
}

.tableList {
  margin-top: 17px;
}

.tableElement {
  display: flex;
  border-bottom: 1px solid;
  border-top: 1px solid;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.27px;
  line-height: 17px;
  text-align: justify;
}

.tableFirstRow {
  width: 40%;
  padding: 9px 0px;
}

.tableSecondRow {
  padding: 9px 50px 9px 30px;
  background-color: #4b5158;
  width: 60%;
}

.nameContainer {
  display: flex;
  flex-flow: column;
}

.methodologyText {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.78px;
  line-height: 14px;
  color: #2962ff;
}

.methodologyInput {
  height: 43px;
  margin-bottom: 20px;
  font-size: 14px;
}
.checkerContainer {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 130px;
}

.lockSwitch {
  margin: 0 !important;
}

.saveContainer {
  display: flex;
  padding-top: 20px;
  margin-top: auto;
  justify-content: center;
  width: 100%;
}

.dividerModal {
  width: 97%;
  height: 1px;
  background: white;
  margin: 0px 10px 10px 10px;
}
