@import './Variables.scss';
@import '../static/font/stylesheet.css';

body {
  background-color: #1d1d1d;
  overflow-x: hidden;
  letter-spacing: 1px;
}

.main {
  display: flex;
  background-color: $background-color-main;
  color: $text-color-main;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  min-height: 100vh;
  font-size: 13px;
}

h2,
div {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.Analysis .cardcontainer {
  justify-content: center !important;
}

input {
  outline: 2px solid transparent !important;
  background-color: #dfe;
  border: 0;
}

input[type='text'] {
  background-color: transparent;
}

input:focus {
  outline: 2px solid transparent !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.searchInput {
  width: 180px !important;
  background-color: transparent;
  background-image: url('../static/img/Icons/search.svg');
  background-repeat: no-repeat;
  background-position: 0px 10px;
  padding-left: 15px !important;
  font-size: 12px;
  height: 35px;
}

.navbar {
  background: $background-color-main;
}

.content {
  background-color: #2a2d31 !important;
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 10px;
}

.content-Widget {
  background-color: #3b3f44 !important;
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 18px;
}

.disabled-component {
  pointer-events: none;
  opacity: 0.3;
}

.hidden-component {
  display: none !important;
}

// checkboxes
input[type='checkbox'].css-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

input[type='checkbox'].css-checkbox + label.css-label {
  padding-left: 25px;
  height: 19px;
  display: inline-block;
  line-height: 19px;
  background-repeat: no-repeat;
  background-position: 0 0;
  color: $input-text-color;
  font-size: 12px;
  vertical-align: middle;
  cursor: pointer;
}

input[type='checkbox'].css-checkbox:checked + label.css-label {
  background-position: 0 -19px;
}
label.css-label {
  background-image: url('../static/img/Icons/bitacheckbox.png');
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//notificaciones
.Toastify__toast--default {
  background: linear-gradient(270deg, #0234b3ef 10%, #2962ff 100%) !important;
  color: #ffffff !important;
  font-weight: bold !important;
  z-index: 999992399;
}

.Toastify__toast--warning {
  background: linear-gradient(270deg, #f79f07 10%, #ceb305ef 100%) !important;
  color: #ffffff !important;
  font-weight: bold !important;
  z-index: 999992399;
}

.Toastify__toast-container {
  z-index: 99999999 !important;
}

.Toastify__close-button--default {
  color: #ffffff !important;
  opacity: 1 !important;
}

/////

/// borrar de aquí para abajo ?

.text-electricalblue .img {
  filter: grayscale(0%);
}

// fix de la tabla
.ReactVirtualized__Table__rowColumn {
  display: table-cell !important;
}

.full-heigh-less-navbar {
  height: -webkit-calc(100vh - 112px);
  height: -moz-calc(100vh - 112px);
  height: calc(100vh - 112px);
}

.sidebar {
  background-color: #222222;
  height: -webkit-calc(100vh - 78px);
  height: -moz-calc(100vh - 78px);
  height: calc(100vh - 78px);
}
.login {
  height: -webkit-calc(100vh - 78px);
  height: -moz-calc(100vh - 78px);
  height: calc(100vh - 78px);
}
.background-gray1 {
  background-color: #323232;
}

// resulpanel style
.resultpanel {
  background-color: #323232;
}
.ReactTable .rt-thead .rt-th {
  background-color: #222222;
  color: #4aa9db;
}

.ReactTable .rt-tbody .rt-td {
  border: none !important;
  text-align: center;
}

.ReactTable .rt-tbody .rt-td:nth-child(2) {
  color: #4aa9db;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: 0.5;
  cursor: default;
  color: white;
  background-color: #222222;
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  color: #222222;
}

.bg-red-700 {
  background-color: #9b2c2c;
}

.titlePrueba {
  color: white !important;
  margin-top: 30px;
}
.ReactModal__Content {
  user-select: none;
}
.ReactModal__Content .simplebar-track.simplebar-vertical {
  top: 0;
  right: -10px;
  width: 11px;
}

.modaldataUpload .simplebar-track.simplebar-vertical {
  top: 0;
  right: 0px !important;
  width: 11px;
}

.tablemodal .simplebar-track.simplebar-vertical {
  right: 0px !important;
}

.modalRevisionUniverse .simplebar-track.simplebar-vertical {
  right: 10px !important;
}
.no__progress__value__indicator {
  width: 100%;
  .react-sweet-progress-symbol {
    display: none;
  }
}

/*REMOVE NATIVE SCROLL BAR IN MODALS AND POPUPS*/
.ReactModal__Content.ReactModal__Content--after-open {
  overflow: hidden !important;
}

svg {
  pointer-events: none;
}

.ChartContainer .highcharts-axis-labels span {
  word-break: break-all !important;
  width: 200px !important;
  white-space: normal !important;
}

.modalRevisionUniverse .builderRadioGroup {
  align-items: flex-end;
}

.backtestcompletedropdown .dropdown__option,
.backtestcompletedropdown .dropdown__single-value,
.backtestcompletedropdown .dropdown__placeholder {
  text-transform: capitalize !important;
}

.infinite-scroll-component__outerdiv {
  width: 100% !important;
}

button:focus,
.autofocusfixed {
  outline: 1px solid transparent !important;
  box-shadow: 0px 0px 0px 2px white !important;
  -moz-box-shadow: 0px 0px 0px 2px white !important;
  -webkit-box-shadow: 0px 0px 0px 2px white !important;
}
.simplebar-content-wrapper::-webkit-scrollbar {
  display: none;
}

.simplebar-content-wrapper {
  padding-right: 20px !important;
}

.simplebar-offset {
  right: -20px !important;
}

#tracking_error {
  color: #ffffff !important;
}
