@import '../../sass/Variables.scss';

// Sidebar
.sidebar {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  position: fixed;
  // height: 100%;
  height: 100vh;
  background: #2a2d31;
}

.sidebarNavigation {
  flex-grow: 1;
  height: calc(100vh - 300px);
}

.container {
  padding-top: 25px;
}

.logo {
  width: 240px;
}

.logoResponsive {
  display: none;
}

.sidebarSeparatorBox {
  padding: 10px 0;
}

.sidebarSeparator {
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid rgba($text-color-main, 0.6);
}

/// Inner Userbar

.userbar {
  display: flex;
  cursor: pointer;
  padding: 3px 30px;
}

.userTopbar {
  cursor: pointer;
  &:hover {
    background: rgba($text-color-main, 0.2);
  }
}
.userbarSelected {
  display: flex;
  padding: 3px 30px;
  color: #2962ff !important;
  background: #ffffff !important;
  border-right: 3px solid #2962ff;
  transition: all 0.2s;
  &:hover {
    background: rgba($text-color-main, 0.2);
  }
  & svg {
    & path {
      fill: $text-color-tertiary !important;
    }
  }
}

.accountP {
  display: flex;
  align-items: center;
  font-size: 12px;
  svg {
    margin-right: 7px;
  }
}

.inputTitle {
  display: block;
  color: #336cff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
}

.column {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
  input {
    background-color: unset;
    border-radius: 2.55px;
    font-size: 13px;
    width: 100%;
    height: 35px;
    line-height: 25px;
    overflow: hidden;
  }
}

.userDataRightSide {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  justify-content: center;
}

.username {
  font-size: 14px;
  color: $text-color-tertiary;
  font-weight: $font-weight-semibold;
  height: 20px;
}

.email {
  color: #a7a7aa;
  font-size: 12.64px;
  height: 20px;
  font-weight: $font-weight-light;
}

.userPic {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 40px;
  height: 40px;
  font-size: 15px;
  font-weight: $font-weight-semibold;
  background: linear-gradient(270deg, $text-color-secondary 0%, $text-color-quaternary 100%);
}

.iconSuccess {
  color: green;
  margin-right: 8px;
}

.iconFailure {
  color: red;
  margin-right: 8px;
}

.policyMet {
  display: flex;
  align-items: center;
}

.policyNotMet {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .logo {
    width: 190px;
  }
  .userbar {
    padding: 3px 20px;
  }
}
