@import '../../sass/Variables.scss';

.summaryPanel {
  display: flex;
  height: calc(100vh - 60px);
  flex-direction: column;
  color: $input-text-color;
  background: $background-color-main;
}

.summaryUnmount {
  display: none !important;
}

.summaryHidden {
  width: 21px;
  max-width: 21px;
  transition: all 300ms;
}

.summaryShown {
  width: 259px;
  max-width: 259px;
  transition: all 300ms;
}

.title {
  font-size: 16px;
  font-weight: $font-weight-semibold;
  color: $text-color-main;
  letter-spacing: 2.58px;
  margin-left: 15px;
  justify-content: center;
}

.section {
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  color: $text-color-main;
  height: 41px;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  background-color: #2f2f2f;
  padding-left: 37px;
  font-size: 11px;
  letter-spacing: 1.78px;
  svg {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    background: #fff;
    border-radius: 2px;
  }
}

.subSection {
  display: flex;
  flex-direction: column;
}

.subSectionTitle {
  margin-top: 10px;
  font-size: 13px;
  letter-spacing: 2.1px;
}

.sectionsContainer {
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70vh;
}

.sectionContent {
  padding-left: 37px;
  padding-right: 30px;
  padding-top: 10px;
}

.index-builder {
  height: 16px;
  width: 104px;
  color: #ffffff;
  font-size: 11px;
  letter-spacing: 1.78px;
  line-height: 16px;
  text-align: justify;
}

.header {
  display: flex;
  align-items: center;
  background-color: $background-color-secondary;
  height: 41px;
}

.bugerdiv {
  min-width: 21px;
  padding: 6px;
  width: 21px;
  height: 100%;
  background: linear-gradient(0deg, #2962ff 0%, #0039cb 100%);
}

.bugerdiv:hover {
  background: linear-gradient(0deg, #fff 0%, #fff 100%);
}
.header > .bugerdiv:hover .iconNormal {
  display: none;
}
.header > .bugerdiv .iconHover {
  display: none;
}
.header > .bugerdiv:hover .iconHover {
  display: block;
}
.bugerdiv:hover {
  background-color: red;
}
.contents {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
}

.summaryItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.summaryItemRemove {
  cursor: pointer;
}

.summaryItemData {
  display: flex;
  flex-direction: column;
  font-weight: $font-weight-semibold;
  margin-left: 5px;
  font-size: 12px;
  letter-spacing: 1.94px;
  color: #5389ff;
  text-transform: uppercase;
  line-height: 19px;
}

.itemContent {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  letter-spacing: 1.94px;
  line-height: 19px;
  font-weight: 400;
  color: #fff;
  text-transform: none;
}

.summaryItemRemove {
  height: 19px;
  width: 19px;
  display: flex;
  align-items: center;
}

.itemEntryUpper {
  text-transform: uppercase;
}

.previewValidateContainer {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 5px;
  border-top: 1px solid;
  padding: 11px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.previewModalContainer {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.previewModalTitleContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 78px;
}

.previewModalTitle {
  color: #ffffff;
  font-size: 18.4px;
  letter-spacing: 2.97px;
  margin-left: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
}

.previewModalTitle svg {
  margin-right: 10px;
  height: 40px;
  width: 40px;
}

.previewModalChartContainer {
  width: 65vw;
  height: 85vh;
  max-height: 680px;
}

.previewModalButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
}

.previewModalButtonText {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 2.58px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 15px;
}

.tabsComponentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.tabsContainer {
  display: flex;
  align-self: flex-end;
  margin-right: 20px;
}

.tabContentContainer {
  width: 95%;
  margin: auto;
  height: calc(100% - 120px);
}

.tabContentContainer tr svg {
  display: none;
}

.tabTitleButton {
  cursor: pointer;
  margin-right: 9px;
  height: 50px;
  width: 140px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 1.48px;
  line-height: 20px;
  text-align: center;
  border-radius: 3.15px;
}

.tabTitleButtonActive {
  background: #2962ff;
}
.tabTitleButtonInactive {
  background-color: #3b3f44;
}
.graphContainer {
  width: 100%;
  height: 100%;
  margin: auto;
}
.filtersImpactContainer {
  width: 100%;
  margin: auto;
  height: 95%;
}
.dividerModal {
  width: 95%;
  height: 1px;
  background: white;
  margin: 15px auto;
}

.chartTitle {
  color: #2962ff;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3.23px;
  line-height: 25px;
  text-align: justify;
}
.chartButtons {
  width: 40%;
  display: flex;
}
.chartTitleContainer {
  display: flex;
  margin: 10px auto;
  width: 100%;
  height: 27px;
  justify-content: space-between;
}

.chartButtonsWhite {
  margin-right: 10px;
}

.chartWithSub {
  padding: 10px 20px 10px 0px;
}

.chartSubTitle {
  color: #ffffff;
  margin-top: 10px;
  font-size: 13px;
  letter-spacing: 2.1px;
  line-height: 16px;
  text-align: justify;
}
.exclusionTitle {
  font-size: 12px;
  margin: 5px 0 10px 0;
  font-weight: bold;
  letter-spacing: 1.94px;
  line-height: 15px;
  text-align: justify;
  color: #e31212;
  display: none;
}

.loadingBarBackground {
  margin-top: 10px;
  height: 15px;
  background-color: white;
  border-radius: 5px;
  width: 180px;
}

.loadingBar {
  height: 100%;
  border-radius: 5px;
  background-color: #fa5400;
}

.widgetContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.separator {
  border-bottom: 0.5px;
  border-color: rgba(255, 255, 255, 0.5);
  border-style: solid;
  height: 1px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.rowWidget {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  :first-child {
    margin-right: 5px;
  }
}

.summaryEdit {
  display: none;
}

.summaryUniverse:hover ~ .summaryEdit {
  display: inline;
}

@media (max-width: 768px) {
  .summaryShown {
    position: fixed;
    right: 0px;
    width: 259px;
    height: 100%;
    max-width: 259px;
    transition: all 300ms;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
  }
}
