.input {
  border: 1px solid hsl(0, 0%, 80%) !important;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 5px;
  width: 210px;
  font-size: 12px;
  color: white !important;
  font-weight: 500;
  cursor: pointer;
  background-image: url('../../static/img/Icons/date.svg');
  background-repeat: no-repeat;
  background-position: 4px center;
  text-indent: 30px;
  background-size: 20px;
  display: flex;
  align-items: center;
  width: 300px;
  padding-left: 28px !important;
  height: 34px;
  border: 1px solid rgba(9, 113, 163, 1);
  border-radius: 3px;
}

.calendar {
  position: absolute;
  z-index: 9999999;
}

.text {
  color: #2962ff;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 14px;
  margin-top: 0px;
}
