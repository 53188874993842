.previewModalContainer .constituents {
  width: 100%;
  margin: auto;
}
.previewModalContainer .constituents .firstRownIcon {
  display: none !important;
}
.previewModalContainer .constituents .buttonMultiSelect {
  position: absolute;
  z-index: 999999999;
  width: 20px;
  height: 20px;
  color: white;
  background: transparent;
  padding: 0px;
  border-radius: 3px;
}

.previewModalContainer .constituents .content-Widget {
  height: 410px !important;
  padding: 0px !important;
  background: transparent !important;
}

.previewModalContainer .constituents tbody {
  background-color: #3b3f44 !important;
}
.builderSelect {
  width: 100%;
}
.previewModalContainer .constituents table {
  table-layout: fixed;
}

.previewModalContainer .constituents thead {
  border-bottom: 2px solid #2962ff;
}
.previewModalContainer .constituents td {
  width: 20px !important;
  padding-left: 4px;
}
.previewModalContainer .constituents table tr td:nth-child(3) {
  width: 20% !important;
}

.previewModalContainer .constituents table tr td:nth-child(1) {
  width: 80px !important;
}

.previewModalContainer .constituents table tr td:nth-child(1) span {
  padding-left: 20px;
}

.previewModalContainer .constituents table tr td:nth-child(1) span:hover {
  background: transparent !important;
  position: static;
  width: 90% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  margin-top: 0px !important;
}

.close {
  width: 10px;
  position: relative;
  left: 99%;
  margin-top: -17px;
  font-weight: 500;
  font-size: 30px;
  cursor: pointer;
  font-weight: bold;
}

.previewModalContainer .constituents .tabletitle {
  color: #2962ff !important;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 3.23px;
  line-height: 25px;
  text-align: justify;
}

.multidropdown__control {
  border-radius: 5px !important;
  background: #2a2d31 !important;
  color: white !important;
  border-width: 1px !important;
  border: solid white;
}

.multidropdown-container {
  margin-left: 10px;
}

.multidropdown__placeholder {
  font-weight: 500 !important;
  color: white !important;
}

.multidropdown__multi-value {
  background: linear-gradient(270deg, #2962ff 0%, #0039cb 100%);
  color: #ffffff;
}

.multidropdown__multi-value__label {
  color: #ffffff !important;
}
