// background color for main panels (topbar, leftbar, etc)
$background-color-main: #1d1d1d;

// background color for secondary panels
$background-color-secondary: #29292a; // gris oscuro

//color de los botones
$background-button-primary: linear-gradient(270deg, #2962ff 0%, #0039cb 100%);
$background-button-primary-hover: linear-gradient(270deg, #768fff 0%, #2962ff 100%);

$background-button-primaryWhite: #ffffff;
$background-button-primaryWhite-hover: linear-gradient(
  90deg,
  #ffffff 0%,
  #d5d5d5 97%,
  #d4d4d4 100%
);

$text-color-main: #ffffff;
$text-color-secondary: #768fff;
$text-color-tertiary: #2962ff;
$text-color-quaternary: #0039cb;

$input-bottom-border-color: #c7c7c7;
$input-text-color: #ffffff;
$input-placeholder-text-color: #c7c7c7;

$input-bottom-border-color-secondary: #fff;
$input-text-color-secondary: #fff;
$input-placeholder-text-color-secondary: #fff;

// fuentes
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
