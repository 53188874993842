.tableCharacteristics {
  .tablemodal {
    > div > div:nth-child(1) {
      display: none;
    }
  }
  tbody {
    background-color: #4b5158;
    tr {
      height: 40px;
      border-bottom: 1px solid white;
    }
    span {
      font-weight: 400;
    }
  }
  thead {
    background-color: #3b3f44;
    border-bottom: 1px solid #2962ff;
    th {
      text-transform: uppercase;
    }
  }
  .simplebar-content {
    padding: 0px 0px 0px 0px !important;
    margin-right: 10px;
    min-width: calc(100% - 10px);
  }
  svg {
    display: none;
  }
}
