.dropdownContainer {
  border-radius: 5px;
  background: #2a2d31 !important;
  font-weight: 500 !important;
  font-size: 13px;
}

.dropdown__control {
  min-height: 35px !important;
  max-height: 35px !important;
  border-radius: 5px !important;
  background: #2a2d31 !important;
  color: white !important;
  border-width: 1px !important;
  border: 1px solid white;
}

.dropdown__control--menu-is-open,
.dropdown__control--is-focused {
  background: white !important;
  color: #2a2d31 !important;
}

.dropdown__control--menu-is-open .dropdown__placeholder,
.dropdown__control--is-focused .dropdown__placeholder,
.dropdown__control--is-focused .dropdown__single-value,
.dropdown__control--menu-is-open .dropdown__indicator,
.dropdown__control--is-focused .dropdown__indicator {
  color: #0f0f0f !important;
}

.dropdown__value-container {
  border-radius: 5px;
  color: #2a2d31 !important;
  margin-top: 0px;
}

.dropdown__placeholder {
  font-weight: 500 !important;
  top: 47% !important;
  color: white !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dropdown__indicators {
  height: 35px;
}

.dropdown__indicator {
  padding: 4px 2px !important;
  color: white !important;
  margin-top: -1px;
}

.dropdown__input input {
  color: #2a2d31;
  font-weight: 500;
}

.dropdown__menu {
  min-width: 250px;
  font-weight: 500;
  margin-top: 5px !important;
  padding: 0px !important;
  border-radius: 5px !important;
  overflow: hidden;
  padding: 0px !important;
  border: 1px solid white;
  opacity: 1 !important;
}

.dropdown__menu > div {
  height: 100%;
}

.dropdown__single-value {
  font-weight: 500;
  top: 45% !important;
  color: #fff !important;
}

.dropdown___menu-list {
  padding-top: 0px !important;
  margin: 0px !important;
  color: #2a2d31 !important;
}

.dropdown___menu-list div {
  color: #2a2d31 !important;
}

.dropdown__option {
  background: #2a2d31;
  margin: 0 !important;
  font-weight: 500 !important;
}

.dropdown__option:first-child {
  position: relative;
  top: -4px;
}

.dropdown__option:hover {
  background: white !important;
  color: #2a2d31 !important;
}

.option--is-disabled {
  opacity: 0.5 !important;
}
