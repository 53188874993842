.filters {
  padding-top: 5px;
  margin-top: 25px;
  margin-left: -35px;
  padding-left: 35px;
  border-top: 1px solid gray;
}

.constituents .bita_spinner {
  margin-top: 25%;
}
.summaryEdit {
  visibility: hidden;
  transition: visibility 0.2s;
  position: absolute;
  right: 260px;
  width: 200px;
  margin-top: 41px;
  background: #4b5158;
  padding-bottom: 20px;
  > div > div {
    padding-right: 15px;
    padding-left: 25px;
  }
}

.summaryUniverse:hover {
  background: #4b5158;
}

.summaryUniverse:hover ~ .summaryEdit {
  visibility: visible;
}
.summaryEdit:hover {
  visibility: visible;
}
