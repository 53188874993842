.contentVideo {
  background: #2a2d31;
  width: calc(100% - 420px);
  padding: 22px 22px 22px 7px;
  .contentFrameVideo {
    margin-bottom: 16px;
    background-color: #000;
    position: relative;
    display: block;
    contain: content;
    aspect-ratio: 16/9;
    background-position: 50%;
    background-size: cover;
    cursor: pointer;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .descriptionVideo {
    margin-bottom: 26px;
  }
}
.contentInformationVideo {
  border-bottom: 1px solid #7c7c7c;
  padding: 8px 32px;
  div {
    width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
  }
  &:hover {
    background: #33363b;
  }
}
.activeVideo {
  background-color: #7c7c7c !important;
}

.contentIframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.contentListVideo {
  background: #2a2d31;
  padding-top: 24px;
  input {
    width: 350px !important;
    margin-bottom: 16px;
    margin-left: 16px;
  }
}
