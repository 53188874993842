.base {
  padding: 10px;
  border-radius: 8px;
  background-color: #2a2d31;
  height: 100%;
}

.firstHalfContainer {
  height: 55%;
  display: flex;
  justify-content: space-between;
}

.halfWidthContainer {
  width: 50%;
  justify-content: space-between;
  display: flex;
  flex-flow: column;
}

.benchmarkSearchDropdown {
  width: 100%;
  margin-right: 10px;
}

.baseInfoContainer {
  display: flex;
  margin: 3px 10px 3px 5px;
  height: 70%;
  border-radius: 5px;
  background-color: #3b3f44;
  flex-flow: column;
}

.baseInfoTitleContainer {
  padding: 20px;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
  text-transform: uppercase;
}

.baseInfoTitle {
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }
}

.baseTitle {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.78px;
  line-height: 14px;
  color: #2962ff;
}

.baseDescription {
  font-size: 12px;
  letter-spacing: 1.94px;
  line-height: 15px;
}

.baseNamesContainer {
  display: flex;
  flex-flow: column;
}

.baseContainer {
  display: flex;
  flex-flow: column;
  margin-bottom: 10px;
  margin-left: 20px;
}

.benchmarkContainer {
  padding: 20px;
  width: 100%;
  flex-flow: column;
  margin-top: auto;
  display: flex;
}

.benchmarkTitle {
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.78px;
  margin-bottom: 5px;
  line-height: 14px;
  color: #2962ff;
}

.dropdownContainer {
  width: 95% !important;
}

.styleFactorDropwdownContainer {
  width: 15% !important;
}

.dropdownFactorContainer {
  width: 100%;
}

.benchmarkSearchDropdown {
  width: 100%;
  height: 36px;
}
.benchmarkSearch {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.downloadContainer {
  display: flex;
  margin: 3px 10px 3px 5px;
  border-radius: 5px;
  background-color: #3b3f44;
  height: 27%;
  flex-flow: column;
  padding: 20px;
  justify-content: center;
}

.downloadTitle {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
}

.downloadButtonsContainer {
  display: flex;
  flex-wrap: wrap;
}

.generalStatsContainer {
  margin: 3px 0px;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  background-color: #3b3f44;
}

.generalTitleContainer {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
}

.bottomHalfContainer {
  width: 100%;
  height: 45%;
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
}

.dateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px 10px 3px 5px;
  border-radius: 5px;
  background-color: #3b3f44;
  height: 50px;
  padding: 15px;
  button {
    width: 80px !important;
    margin-left: 10px;
    font-size: 11px;
  }
}

.constituentsContainer {
  margin: 3px 5px;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  background-color: #3b3f44;
}

.constituentsTitleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.weightsChartContainer {
  border-radius: 5px;
  background-color: #4b5158;
  margin-top: 10px;
  height: 45%;
}

.sectorsChartContainer {
  border-radius: 5px;
  display: flex;
  padding: 20px;
  justify-content: center;
  background-color: #4b5158;
  margin-top: 10px;
  height: 84%;
}

.sectorAndWeightsContainer {
  margin: 3px;
  width: 49%;
  padding: 20px;
  border-radius: 5px;
  background-color: #3b3f44;
}

.tabStyle {
  cursor: pointer;
  margin-right: 9px;
  height: 38px;
  width: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 11px;
  letter-spacing: 1.48px;
  line-height: 15px;
  text-align: center;
  border-radius: 3.15px;
  background-color: #4b5158;
}

.tabStyleActive {
  cursor: pointer;
  margin-right: 9px;
  height: 38px;
  width: 290px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 11px;
  letter-spacing: 1.48px;
  font-weight: bold;
  line-height: 15px;
  text-align: center;
  border-radius: 3.15px;
  background-color: #2962ff;
}

.performanceTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.styleFactorTableContainer {
  display: flex;
  justify-content: center;
  background-color: #4b5158;
  height: 90%;
  margin-top: 12px;
  border-radius: 5px;
  align-items: center;
}

.performanceTitle {
  font-size: 15px;
  text-transform: uppercase;
  /* margin-bottom: 21px; */
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
}

.performanceButtonsContainer {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.performanceTabs {
  display: flex;
  margin-bottom: 15px;
}

.performanceChartContainer {
  border-radius: 5px;
  background-color: #4b5158;
  height: 90%;
  margin-top: 17px;
}

.performanceChart {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.bitaButtonCustom {
  margin: 0px 5px 5px 0px;
}

.radioButtonChart {
  box-sizing: border-box;
  height: 14px;
  width: 14px;
  border: 1px solid #ffffff;
  border-radius: 9px;
}

.radioButtonChartSelected {
  box-sizing: border-box;
  height: 14px;
  width: 14px;
  border: 1px solid #2962ff;
  border-radius: 9px;
}

.radioButtonsSelected {
  display: flex;
  justify-content: space-between;
}
.radioButton {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.radioButton:focus {
  outline: 0;
}

.radioButtonsContainer {
  display: flex;
  flex-flow: column;
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-weight: 600;
    font-size: 11px;
    color: white;
    margin-right: 5px !important;
  }
}

.radioButtonChartSelectedPoint {
  box-sizing: border-box;
  height: 5.25px;
  width: 5.25px;
  border: 1px solid #2962ff;
  border-radius: 3.38px;
  position: absolute;
  background-color: #2962ff;
}

.radioButtonChartContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radioButtonName {
  margin-left: 5px;
}

.performanceStatsContainer {
  margin: 3px 3px 3px 0px;
  width: 50%;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
  background-color: #3b3f44;
}

.performanceLabelsContainer {
  margin-top: 4px;
  height: 323px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.labelTick {
  font-size: 12px;
  font-weight: bold;
  height: 22.1px;
  line-height: 10px;
  text-align: center;
  padding: 10.9px 0px 0px;
}
.filterContainer {
  display: flex;
  width: 100%;
  align-items: center;
  p {
    margin-right: 10px;
  }
}
.filterDropdown {
  width: 250px;
}

.radioContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.styleFactorContainer {
  margin: 3px 0px 3px 3px;
  margin-right: 10px;
  width: 50%;
  padding: 20px;
  border-radius: 5px 0px 0px 5px;
  background-color: #3b3f44;
}

.styleFactorTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styleFactorTitle {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;

  text-transform: uppercase;
}

.generatedTable {
  margin-bottom: 10px;
  table-layout: fixed;
  width: 100%;
  border-radius: 5px;
}

.tableTbody {
  background-color: #4b5158;
}

.tableHeaderRow {
  font-size: 11.3px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 22px;
  text-align: justify;
  background-color: #3b3f44;
}

.tableHeaderContainer {
  background-color: #3b3f44;
  border-bottom: 1px solid blue;
}

.generatedTableHeader {
  border-bottom: 1px solid;
}

.restTableHeaders {
  text-align: center;
  border-left: 1px solid;
  /* text-align: left; */
  padding: 5px;
}

.firstTableHeader {
  padding: 5px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 2.1px;
  line-height: 16px;
  text-align: justify;
  color: #2962ff;
}
.firstTableValue {
  color: #ffffff;
  /* font-family: "Neue Haas Grotesk Text Pro"; */
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
}

.tableValueRow {
  border-bottom: 1px solid;
}

.generatedTableValues {
  text-align: center;
  border-bottom: 1px solid;
  height: 32px;
  table-layout: fixed;
}
.restTableValues {
  padding: 5px;
  border-left: 1px solid;
  text-align: left;
}

.tableContainer {
  border-radius: 5px;
  background-color: #4b5158;
  height: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.tableContainerGenerated {
  border-radius: 5px;
  height: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.generatedTableRowContent {
  border-left: 1px solid;
}

.attributionChartsContainer {
  width: 100%;
  height: 100%;
  align-items: flex-start;
  display: flex;
}

@media (max-width: 768px) {
  .base {
    padding: 20px;
  }
  .firstHalfContainer {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .halfWidthContainer,
  .sectorAndWeightsContainer {
    width: 50%;
  }
  .baseInfoContainer,
  .performanceStatsContainer {
    margin: 0px;
  }
  .downloadContainer {
    margin: 12px 0px 12px 0px;
  }
  .performanceTitleContainer {
    flex-wrap: wrap;
  }

  .bottomHalfContainer {
    flex-wrap: wrap;
    > div {
      width: 100%;
      margin-top: 12px;
      border-radius: 5px;
    }
  }
  .sectorAndWeightsContainer button {
    top: -558px !important;
  }
}
