/* autoprefixer grid: autoplace */

.pageContainer {
  border-radius: 8px;
  background: #2a2d31;
  height: 100%;
}

.pageContainer button {
  /* flex-grow: 1; */
}

.optimizationStep {
  border-radius: 5px;
  height: 100%;
  background: #3b3f44;
  margin: 20px;
  padding-bottom: 150px;
}

.stepTitle {
  padding: 21px 15px;
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2.42px;
  text-align: justify;
}

.optimizationStepHeader {
  display: flex;
  justify-content: space-between;
}

.optimizationWidgetTitle {
  font-weight: 600;
  text-transform: uppercase;
}

.stepSubtitle {
  padding: 0 15px;
  font-size: 15px;
  letter-spacing: 1.71px;
}

.stepNavigation {
  margin-top: 20px;
  min-width: 286px;
  display: flex;
  flex-direction: row;
}

.stepNavigationButton {
  width: 120px !important;
  height: 35px;
  margin-right: 20px;
  visibility: hidden;
}

.stepNavigationButtonVisible {
  visibility: visible !important;
}

.stepTitle svg {
  border: 1px solid #fff;
  padding: 1px;
  border-radius: 3px;
  width: 17px;
  height: 17px;
}

.stepTitle span {
  margin-left: 15px;
}

/* Optimization component type card */
.cardOptimizationComponentContainer {
  display: flex;
  padding: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.optimizationCard {
  margin: 30px 30px 0 0 !important;
  cursor: pointer;
  .title {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 2.42px;
    line-height: 19px;
    text-align: justify;
  }
}

.optimizationSwitchComponentContainer {
  display: flex;
  position: relative;
  flex-flow: column;
  background: #4b5158;
  padding: 15px;
  border-radius: 8px;
  margin-top: 5px;
  width: 100%;
}

.optimizationSwitchComponentContainer > div b ~ span {
  margin-left: 0px !important;
}

.optimizationSwitchComponentTop {
  .optimizationSwitchComponentName {
    text-transform: uppercase;
  }
}

.optimizationSwitchComponentTop ~ div .optimizationSwitchComponentName {
  text-transform: capitalize;
}

.optimizationSwitchContent {
  min-height: 15px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  margin-bottom: 20px;
  > div .optimizationSwitchComponentTop {
    padding-bottom: 10px;
  }
  .optimizationSwitchContent {
    padding-top: 0px;
    margin-left: 0px;
    margin-top: -10px;
    padding-bottom: 10px;
  }
  &.optimizationSwitchContentHorizontal {
    display: inline-block;
  }
}

.optimizationSwitchContent .optimizationWidgetTitle {
  width: 50%;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
  text-transform: uppercase;
}

.widgetDescription {
  width: 100%;
  font-size: 13px;
  letter-spacing: 1.48px;
  line-height: 19px;
  text-align: justify;
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.4);
}

.optimizationWidgetHeaderContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.optimizationSwitchComponentTop {
  width: 280px;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.addibleRow {
  display: flex;
  align-items: flex-end;
  padding-bottom: 30px;
}

.addibleRow > div:first-child {
  padding-left: 0 !important;
}

.addibleRowRemoveButton {
  width: 31px !important;
  height: 31px !important;
  background: #fff;
  color: #2962ff;
  border: 0;
  font-weight: 700;
}

.addibleRowPlusButton {
  width: 31px !important;
  height: 31px !important;
  background: #fff;
  color: #2962ff;
  border: 0;
  font-weight: 700;
}

.optimizationInputComponentContainer {
  margin-right: 15px;
  // width: 200px;
  justify-content: flex-start;
}

.optimizationInputTitle {
  margin-right: 15px;
}

.optimizationInputContainer {
  // margin-left: 15px;
  display: flex;
  span {
    padding-top: 0px !important;
  }
  span:first-child {
    margin-right: 15px;
  }
  span:last-child {
    margin-left: 15px;
  }
}

.widgetInput {
  background-color: unset;
  background: #616870 !important;
  border-radius: 2.55px;
  font-size: 13px;
  width: 100px;
  height: 35px;
  line-height: 25px;
  overflow: hidden;
  color: #fff !important;
}
.widgetInput ~ label {
  top: 11px;
}

.optimizationDropdownPretext {
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 15px;
}

.optimizedSelect {
  display: block;
  margin-right: 10px;
  // width: 250px;
}

.applyDeleteContainer {
  position: absolute;
  right: 10px;
  bottom: 30px;
}

.applyDeleteButton {
  margin-right: 10px;
}

.optimizationSwitchComponentName {
  text-transform: capitalize;
  padding-right: 6px;
}

.optimizationSwitchComponentHolder {
  margin-right: 30px;
}

.invertedCheckBox {
  -webkit-flex-direction: row-reverse;
  display: flex;
  flex-direction: row-reverse;

  input + label {
    padding-left: 0px !important;
    padding-right: 28px;
    margin-right: 20px;
    background-position: right 0 !important;
  }
  input:checked + label {
    background-position: right -19px !important;
  }
}

.sortable {
  cursor: all-scroll;
}

.constraintPriorizationRow {
  display: grid;
  grid-template-columns: 30% 20% 15% 20% 5%;
  grid-row: 1;
  font-size: 13px !important;
  margin-top: 10px;
  margin-bottom: 10px;

  span {
    font-weight: 600;
    text-transform: uppercase;
  }

  .penalizationTitle {
    grid-column-start: 4;
    grid-column-end: 4;
    text-align: center;
    margin-left: -25px;
  }
}

.centerRunBacktest {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-top: 20px;
}
