
.containerRange {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    label {
        margin-left: 14px;
    }
}
