@import '../../sass/Variables.scss';

.widgetContainer {
  margin-bottom: 15px;
}

.titleLineSeparator {
  border: 0.5px solid #ffffff66;
  margin: 10px 0;
}

.title {
  font-size: 16px;
  font-weight: $font-weight-bold;
  color: $text-color-main !important;
  letter-spacing: 2.58px;
  line-height: 24px;
  text-transform: uppercase;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  justify-content: flex-start;
  width: 100%;
  margin: auto;
  > div {
    width: 33%;
    padding: 10px;
    max-width: 380px;
    a > div {
      width: 100% !important;
    }
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}

.modalContainer {
  display: flex;
  flex-flow: column;
  min-width: 500px;
  min-height: 330px;
  padding: 12px 22px;
}

.modalTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.universalTitle {
  font-size: 11px;
}

.universalNameContainer {
  font-size: 13px;
  display: flex;
  flex-flow: column;
}

.switcherModalContainer {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}

.switcherModal {
  width: 40%;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.backtestSwitch {
  align-self: flex-start;
}

.shareSwitch {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  font-size: 13px;
  margin-bottom: 9px;
}

.modalButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

@media (max-width: 1090px) {
  .cardContainer > div > a > div {
    padding: 14px;
    p {
      font-size: 11px !important;
    }
    > div > div {
      font-size: 16px !important;
    }
  }
}
