/* autoprefixer grid: autoplace */

$blue: #2962ff;

.arrows_backtest_banner {
  @media (max-width: 1265px) {
    width: 30px;
  }
}

.bita_backtest_banner {
  display: flex;
  align-items: center;
  margin-left: 22px;
  cursor: pointer;

  @media (max-width: 1260px) {
    margin-left: 28px;
  }

  .label {
    font-size: 10px;
    margin: 0;
    padding: 0 10px;
    color: $blue;

    align-items: center;
    line-height: 32px;
    height: calc(100% - 20px);
    font-weight: bold;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    display: none;

    @media (min-width: 1265px) {
      display: none;
    }
  }

  .index_name {
    text-transform: capitalize;
    display: inline-block;
    padding: 5px;
    font-size: 11px;
    border: solid 1px;
    border-radius: 12px;
    cursor: not-allowed;

    &.backtest_completed {
      background-color: $blue;
      cursor: pointer;
    }
  }

  .status_backtest {
    display: flex;
    margin-left: 8px;
    padding: 0 10px;
    border-left: solid white 1px;
    max-width: 300px;
    width: calc(300px - 40px);

  }
}
