.bitaCard {
  width: 200px;
  min-height: 100px;
  transition-duration: 1s;
  border-radius: 8px;
  color: #fff;
  padding: 13px;
  letter-spacing: 1px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);

  .title {
    font-weight: 500;
  }
  &.module .title {
    text-transform: uppercase;
  }
  .text {
    width: 80%;
    font-size: 12px;
    transition-duration: 1s;
  }
}

.bitaCardBlue {
  background: linear-gradient(270deg, #2962ff 0%, #26276b 100%);
}

.bitaCardPurple {
  background: linear-gradient(270deg, #7b89ad 0%, #40444f 100%);
}

.bitaCardBlue:hover {
  background: linear-gradient(270deg, #2962ff 0%, #4aa9db 100%);
}

.bitaCardPurple:hover {
  background: linear-gradient(270deg, #93a1c4 0%, #464850 100%);
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.bitaCard:hover {
  transform: scale(1.08);
}

.bitaCard:hover svg,
.bitaCard:hover img {
  transform: scale(1.15);
}

.universe {
  position: relative;
  overflow: hidden;
  min-height: 270px;
  width: 380px;
  padding: 17px;
  border-radius: 10px;
  .title {
    font-weight: 700;
    font-size: 23px;
    line-height: 25px;
    letter-spacing: 1px;
    width: 50%;
    position: absolute;
    bottom: 0px;
    margin-bottom: 18px;
    text-shadow: 2px 2px #262971;
  }
  svg,
  img {
    position: absolute;
    left: 45%;
    top: 80px;
    width: 70%;
    height: 70%;
    opacity: 0.8;
    transition-duration: 1s;
  }
}

.svgSmall {
  left: 50% !important;
  top: 60px !important;
  width: 72% !important;
  height: 85% !important;
}

.titleWithSelected {
  top: 10px;
}

.withSelectedContent {
  margin-top: 60px;
  font-size: 12px;
}

.filter {
  position: relative;
  height: 140px;
  width: 255px;
  .title {
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 2.42px;
    margin-bottom: 8px;
    font-size: 15px;
  }
  svg,
  img {
    position: absolute;
    right: 10px;
    bottom: 10px;
    float: right;
    width: 45px !important;
    height: 45px !important;
    transition-duration: 1s;
  }
}

.filter:hover {
  div {
    opacity: 1;
  }
}

.module {
  position: relative;
  height: 185px;
  width: 340px;
  overflow: hidden;
  padding: 17px;
  margin: 10px;

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1.94;
    margin-bottom: 16px;
    margin-top: 5px;
  }
  .text {
    width: 62%;
    opacity: 1;
    font-size: 13px;
  }
  svg {
    position: absolute;
    right: -36%;
    bottom: -30%;
    width: 80%;
    height: 80%;
    transition-duration: 1s;
  }
}
