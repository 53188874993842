.cardContainer {
  min-height: 100px;
  background: linear-gradient(270deg, #2962ff, #26276b);
  transition-duration: 1s;
  border-radius: 8px;
  color: #fff;
  letter-spacing: 1px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  padding: 0;
  padding-top: 10px;
  display: flex;
  width: 400px;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.cardContainerRebalancing {
  min-height: 100px;
  background: linear-gradient(270deg, #2962ff, #768fff);
  transition-duration: 1s;
  border-radius: 8px;
  color: #fff;
  letter-spacing: 1px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  padding: 0;
  padding-top: 10px;
  display: flex;
  width: 400px;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}

.firstButton {
  width: 44%;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 8px;
  border-radius: 5px;
  background: white;
  color: black;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.firstText {
  font-weight: bold;
}
.firstValue {
  font-weight: bold;
  font-size: 13px;
  color: #2962ff;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.cardTitle {
  color: white;
  text-align: center;
  padding: 0px 5px;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.94;
  margin-bottom: 16px;
  margin-top: 5px;
}
