@import '../../sass/Variables.scss';

// inputs
input[type='text'],
input[type='number'],
input[type='password'] {
  color: $input-text-color;
  border: none;
  border-bottom: solid 1px $input-bottom-border-color;
  letter-spacing: 1.54px;
  padding-left: 5px;
}
input {
  border: none;
}

input[type='text']:focus,
input[type='number']:focus,
input[type='password']:focus {
}

// For Firefox

input[type='number'] {
  -moz-appearance: textfield;
}
// Webkit browsers like Safari and Chrome
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $background-color-secondary inset !important;
  box-shadow: 0 0 0 30px $background-color-secondary inset !important;
  -webkit-text-fill-color: $input-text-color-secondary !important;
}

input::placeholder {
  // Chrome, Firefox, Opera, Safari 10.1+
  color: $input-placeholder-text-color;
  opacity: 1; // Firefox
}

input::-ms-input-placeholder {
  // Microsoft Edge
  color: $input-placeholder-text-color;
}

input:-ms-input-placeholder {
  // Internet Explorer 10-11
  color: $input-placeholder-text-color;
}

input[type='text'] ~ label,
input[type='number'] ~ label,
input[type='password'] ~ label {
  transition: top 0.1s ease;
  position: absolute;
  font-size: 13px;
  color: #ffffff;
  cursor: text;
  padding-left: 5px;
}
input[type='text']:focus ~ label,
input[type='number']:focus ~ label,
input[type='password']:focus ~ label {
  top: -8px;
}

.bitaInput {
  display: flex;
  position: relative;
}
.tooltip {
  display: none;
  position: absolute;
  width: 280px;
  bottom: 100%;
  //left: -100%;
  border-radius: 5px;
  background-color: #f7f7f7;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
  padding: 3px 8px;
  color: #333333;
  z-index: 1000;
}
input:hover ~ .tooltip {
  display: block;
  color: #333333;
  background: #f7f7f7;
}

.bitaInputLabel {
  border: none !important;
  background-color: transparent !important;
}

.row_input_style {
  margin-top: 0px !important;
  margin-right: 5px;
}

.bitaInputLabel::placeholder {
  color: white;
  opacity: 1;
}

.column {
  display: flex;
  position: relative;
  flex-direction: column;
}
.text {
  margin-right: 10px;
}
.posttext {
  padding-top: 10px;
  margin-left: 10px;
}
.row {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
}
/*

.percentInput {
  position: relative;
}
.percentInput span {
  position: absolute;
  right: 4px;
  color: $input-text-color;
}
.num_percent {
  padding-right: 12px;
}

.widgetInput {
  background-color: unset;
  background: #4b5158;
  border-radius: 2.55px;
  font-size: 13px;
  height: 35px;
  line-height: 25px;
  overflow: hidden;
}
*/
