.clickable_container {
  align-self: center;
  width: 48%;
  font-size: 14px;
  font-weight: 530;
  text-align: center;
}

.clickable_container > div > label {
  cursor: pointer;
}

.clickable_container:hover {
  cursor: pointer;
}

.settings_header {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 21px;
  border-bottom: 1px solid #a7a7aa;
  padding: 10px 0 20px 0;
  text-transform: uppercase;
  min-height: 67px;
  justify-content: space-between;
}

.UploadPortfolio {
  margin-bottom: -30px;
  text-transform: uppercase;
}

.UploadPortfolio .title {
  color: #2962ff !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  margin-right: 5px;
}

.UploadPortfolio .externalTitle {
  color: #2962ff !important;
  font-weight: 600 !important;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.UploadPortfolio .uploadButton {
  margin-top: -10px !important;
}

.title_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

.closeModal {
  font-size: 25px;
  cursor: pointer;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 5px;
  right: 20px;
}

.modal_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: flex-start;
  > div {
    display: flex;
    width: 400px !important;
    flex-direction: row !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin-left: 20px;
    margin-top: 30px;
  }
}

.modal_container > div {
  color: #2962ff;
  font-weight: 600;
  font-size: 12px;
}

.modal_container > div > div {
  flex-direction: row !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-right: 0px;
}

.modal_container > div > div > div {
  color: white;
}

.cfd_type_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.selection-list-container .dropdownField {
  margin-bottom: 10px;
}
