@import '../../../sass/Variables.scss';

.forgotPasswordFlexForm {
  padding: 2rem 2rem 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.aditionalText {
  font-size: 12px;
  text-align: left;
}

.link {
  line-height: 23px;
  font-size: 12px;
  color: $text-color-secondary;
  font-weight: $font-weight-semibold;
}

.aditionalInfo {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.spacer {
  height: 1rem;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  gap: 10px;
}

.input {
  width: 100%;
  font-size: 14px;
}

.buttonInactive {
  opacity: 0.5;
  pointer-events: none;
}

.subHeadingText {
  margin: 0.5rem auto 0 auto;
  text-align: left;
  font-size: 14px;
}

.modalPadding {
  padding: 1rem 2rem;
}

.cancelButton {
  background-color: #ffffff;
  color: $text-color-quaternary;
}

.pupUpTitleContainer {
  display: flex;
  border-bottom: 1px solid #eee;
  align-items: center;
  padding: 10px 0;
}
