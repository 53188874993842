.optimizationSwitchComponent:nth-child(1) > div b ~ span {
  margin-left: 16px;
}

.optimizationSwitchComponent:nth-child(1) > div b ~ span ~ b {
  margin-left: 0px;
}

.optimizationSwitchComponent:nth-child(1) > div b ~ b {
  margin-left: 30px;
}

.addibleRow .selectComponent {
  margin-bottom: 1px;
}

.Maximum > div:nth-child(2) > div {
  width: 220px !important;
}

.Maximum .selectComponent > div {
  margin-bottom: -5px;
}

.Maximum .subcomponente {
  margin-bottom: 0px;
}
.Maximum > div:nth-child(2) > div > div > div > div {
  width: 200px !important;
}

.Maximum > div:nth-child(2) > div > div > div {
  display: flex !important;
  margin-top: 10px;
}

.optionSearch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.optionSearchDisabled {
  opacity: 0.5;
}

.barsearch__option {
  width: 100%;
}

.barsearch__single-value {
  width: 100%;
}

.optimizationSwitchComponent .optimizationSwitchComponent > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.optimizationSwitchComponent .optimizationSwitchComponent > div:nth-child(2) > div {
  margin-bottom: 30px;
}

.Security .optimizationSwitchComponent > div:nth-child(2),
.Country .optimizationSwitchComponent > div:nth-child(2),
.Sector .optimizationSwitchComponent > div:nth-child(2),
.Volatility .optimizationSwitchComponent > div:nth-child(2),
.Return .optimizationSwitchComponent > div:nth-child(2),
.Factor .optimizationSwitchComponent > div:nth-child(2) {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.optimizationInputContainer {
  margin-top: 10px;
}

.buildercheckbox {
  height: 35px;
  display: flex;
  align-items: center;
}

.Allow .optimizationSwitchComponent > div {
  width: 280px !important;
}

.Allow > div > .subcomponente:nth-child(2) .selectComponent ~ div {
  height: 47px !important;
}

.Allow .optimizationInputTitle ~ .optimizationInputContainer {
  margin-top: 0px;
}

.Allow .optimizationSwitchComponent .optimizationInputContainer {
  margin-top: 12px;
}

.Allow .optimizationInputContainer {
  margin-top: -8px;
}

.selectComponent {
  margin-bottom: -5px;
}

.Allow .externalTitle {
  text-transform: uppercase;
  font-weight: 600;
}
