.spinner {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}

.bita_spinner_loader {
  margin: auto;
  min-width: 29px;
}

.spinner svg {
  -webkit-animation: rotate 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
  animation: rotate 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
  -webkit-transform-origin: 50% 65%;
  transform-origin: 50% 65%;
}

.spinner svg polygon {
  stroke-dasharray: 17;
  -webkit-animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
  animation: dash 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 136;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 136;
  }
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
