/* activate toggle */

.accordion_item {
  list-style-type: none;
  border: 1px solid #7c7c7c;
}

.accordion_item .button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid #7c7c7c;
}

.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 2s;
}
.answer_wrapper.open {
  height: auto;
}
