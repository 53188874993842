.disabledDropdown {
  pointer-events: none;
  opacity: 0.9;
}

.contentScrollTable {
  .simplebar-offset {
    right: 0px !important;
  }

  table {
    border: 1px solid lightgray;
  }

  tbody {
    border-bottom: 1px solid lightgray;
  }

  th {
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding: 2px 4px;
  }

  tfoot {
    color: gray;
  }

  tfoot th {
    font-weight: normal;
  }

  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 10px;
    background: rgba(0, 0, 0, 0.5);
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }

  .resizer.isResizing {
    background: blue;
    width: 10px;
    opacity: 1;
  }

  @media (hover: hover) {
    .resizer {
      opacity: 0;
    }

    *:hover > .resizer {
      opacity: 1;
    }
  }

  td > div {
    display: inline-block; /* Allows the width to adjust to the content */
    width: 100px; /* Limits the width to 100% of its container */
    white-space: nowrap; /* Prevents line breaks */
    overflow: hidden; /* Hides overflow */
    text-overflow: ellipsis; /* Adds "..." at the end */
  }

  /* My New CSS for Tooltip */
  th span {
    visibility: hidden;
    width: 200px;
    background-color: white;
    font-size: 8px;
    color: rgb(34, 34, 34);
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 30px;
    margin-left: -60px;
    cursor: pointer;
  }

  th:hover span {
    visibility: visible;
  }
}
