.contentBreadcrumbs span {
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  height: 32px;
  margin-left: 10px;
}

.contentBreadcrumbs span:not(:last-child)::after {
  color: #0039cb;
  content: ' ';
  margin-left: 18px;
  font-size: 30px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #0039cb;
  display: block;
  float: right;
}

.contentBreadcrumbs span:last-child {
  background: #2962ff;
  color: #fff;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  height: 32px;
  margin-left: 10px;
}
