/* autoprefixer grid: autoplace */

.tabsComponentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  flex: 1;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
}

.orderContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 30px;
  margin-left: 10px;
  font-size: 10px;
}

.orderButton {
  padding: 20;
}
.tabsContainer {
  display: flex;
  justify-content: space-between;
  flex: 1;
  min-height: 100%;
  margin-bottom: 15px;
}

.tabsButtonContainer {
  display: flex;
  width: 50%;
}

.tabContentContainer {
  width: 100%;
  height: calc(100% - 123px);
  flex: 1;
}

.tabTitleButton {
  cursor: pointer;
  margin-right: 9px;
  height: 52px;
  width: 250px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 1.48px;
  line-height: 20px;
  text-align: center;
  border-radius: 3.15px;
}

.tabTitleButtonActive {
  background: #2962ff;
}
.tabTitleButtonInactive {
  border-radius: 4.05px;
  background-color: #4b5158;
}

.base {
  border-radius: 8px;
  background-color: #2a2d31;
}

.libraryContainer {
  display: flex;
  flex-flow: column;
  min-height: 700px;
  padding: 23px 19px;
  border-radius: 5px;
  background-color: #3b3f44;
}

.libraryTitleContainer {
  display: flex;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.libraryTitle {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
}

.libraryDescription {
  font-size: 13px;
  width: 100%;
  letter-spacing: 1.48px;
  line-height: 19px;
  text-align: justify;
}

.parentContainer {
  padding: 20px;
  border-radius: 4.05px;
  background-color: #2a2d31;
}

.librarySearchContainer {
  display: flex;
  flex: 1;
}

.librarySearch {
  position: relative;
  top: 75px;
}

.tabContent {
  padding: 20px;
  display: flex;
  overflow: auto;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  background-color: #4b5158;
}

.placeholderContainerCard {
  margin: 0 10px 20px 10px;
  // width: 31%;
  min-height: 214px;
  overflow: hidden;
}

.cardWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  flex: 1;
}

.paginationNumbers {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.paginationButtons {
  margin-left: 4px;
  background-color: #4b5158;
  padding: 6px 10px 6px 10px;
  border-radius: 4.05px;
}

.paginationButtons:hover {
  background-color: #6b7279;
}

.loadingContainer {
  display: flex;
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1400px) {
  .placeholderContainerCard {
    margin: 0 2px 20px 2px;
    // width: 31%;
    min-height: 214px;
    overflow: hidden;
  }
}
@media (max-width: 1335px) {
  .cardWrapper {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;

    flex: 1;
  }
  .placeholderContainerCard {
    margin: 0 10px 20px 10px;
    // width: 31%;
    min-height: 214px;
    overflow: hidden;
    div {
      margin: auto !important;
    }
  }
}

@media (max-width: 1100px) {
  .orderContainer {
    justify-content: flex-start !important;
  }

  .cardWrapper {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
    flex: 1;
  }
  .placeholderContainerCard {
    margin: 0 10px 20px 10px;
    // width: 31%;
    min-height: 214px;
    overflow: hidden;
  }
}
