@import '../../sass/Variables.scss';

.bitabutton:focus,
.bitabutton:hover,
.bitabutton:active {
  outline: none !important;
  box-shadow: none !important;
  outline-style: none !important;
}

.bitabutton {
  outline: none !important;
  box-shadow: none !important;
  outline-style: none !important;
}
.default {
  box-sizing: border-box;
  border: 0.7px solid #ffffffb3;
  border-radius: 3.15px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.primary {
  background-image: $background-button-primary;
  border-radius: 3.15px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  color: $text-color-main;
}
.primary:hover {
  background-image: $background-button-primary-hover;
}

.highlighted {
  border: 2px solid #50c1fb;
  background: #50c1fb;
  color: $text-color-main;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.text {
  color: $text-color-main;
  font-size: 12px;
  letter-spacing: 1.37px;
  font-weight: 500;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.primaryWhite {
  background-color: $background-button-primaryWhite;
  color: $text-color-tertiary;
  border-radius: 2.55px;
  font-size: 12px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.primaryWhite:hover {
  background-image: $background-button-primaryWhite-hover;
  border-radius: 3.15px;
}

.disabled-component {
  opacity: 0.5;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}
