@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-BlackItalic.eot');
  src: local('Neue Haas Grotesk Display Pro 96 Black Italic'), local('NeueHaasDisplay-BlackItalic'),
    url('NeueHaasDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-BlackItalic.woff') format('woff'),
    url('NeueHaasDisplay-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-Mediu.eot');
  src: local('Neue Haas Grotesk Display Pro 65 Medium'), local('NeueHaasDisplay-Mediu'),
    url('NeueHaasDisplay-Mediu.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-Mediu.woff') format('woff'),
    url('NeueHaasDisplay-Mediu.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-Light.eot');
  src: local('Neue Haas Grotesk Display Pro 45 Light'), local('NeueHaasDisplay-Light'),
    url('NeueHaasDisplay-Light.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-Light.woff') format('woff'),
    url('NeueHaasDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-ThinItalic.eot');
  src: local('Neue Haas Grotesk Display Pro 36 Thin Italic'), local('NeueHaasDisplay-ThinItalic'),
    url('NeueHaasDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-ThinItalic.woff') format('woff'),
    url('NeueHaasDisplay-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-RomanItalic.eot');
  src: local('Neue Haas Grotesk Display Pro 56 Roman Italic'), local('NeueHaasDisplay-RomanItalic'),
    url('NeueHaasDisplay-RomanItalic.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-RomanItalic.woff') format('woff'),
    url('NeueHaasDisplay-RomanItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-XXThinItalic.eot');
  src: local('Neue Haas Grotesk Display Pro 16 XXThin Italic'),
    local('NeueHaasDisplay-XXThinItalic'),
    url('NeueHaasDisplay-XXThinItalic.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-XXThinItalic.woff') format('woff'),
    url('NeueHaasDisplay-XXThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-Roman.eot');
  src: local('Neue Haas Grotesk Display Pro 55 Roman'), local('NeueHaasDisplay-Roman'),
    url('NeueHaasDisplay-Roman.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-Roman.woff') format('woff'),
    url('NeueHaasDisplay-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-BoldItalic.eot');
  src: local('Neue Haas Grotesk Display Pro 76 Bold Italic'), local('NeueHaasDisplay-BoldItalic'),
    url('NeueHaasDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-BoldItalic.woff') format('woff'),
    url('NeueHaasDisplay-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-Thin.eot');
  src: local('Neue Haas Grotesk Display Pro 35 Thin'), local('NeueHaasDisplay-Thin'),
    url('NeueHaasDisplay-Thin.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-Thin.woff') format('woff'),
    url('NeueHaasDisplay-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-Black.eot');
  src: local('Neue Haas Grotesk Display Pro 95 Black'), local('NeueHaasDisplay-Black'),
    url('NeueHaasDisplay-Black.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-Black.woff') format('woff'),
    url('NeueHaasDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-XXThin.eot');
  src: local('Neue Haas Grotesk Display Pro 15 XXThin'), local('NeueHaasDisplay-XXThin'),
    url('NeueHaasDisplay-XXThin.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-XXThin.woff') format('woff'),
    url('NeueHaasDisplay-XXThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-MediumItalic.eot');
  src: local('Neue Haas Grotesk Display Pro 66 Medium Italic'),
    local('NeueHaasDisplay-MediumItalic'),
    url('NeueHaasDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-MediumItalic.woff') format('woff'),
    url('NeueHaasDisplay-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-Bold.eot');
  src: local('Neue Haas Grotesk Display Pro 75 Bold'), local('NeueHaasDisplay-Bold'),
    url('NeueHaasDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-Bold.woff') format('woff'),
    url('NeueHaasDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-XThin.eot');
  src: local('Neue Haas Grotesk Display Pro 25 XThin'), local('NeueHaasDisplay-XThin'),
    url('NeueHaasDisplay-XThin.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-XThin.woff') format('woff'),
    url('NeueHaasDisplay-XThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-LightItalic.eot');
  src: local('Neue Haas Grotesk Display Pro 46 Light Italic'), local('NeueHaasDisplay-LightItalic'),
    url('NeueHaasDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-LightItalic.woff') format('woff'),
    url('NeueHaasDisplay-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('NeueHaasDisplay-XThinItalic.eot');
  src: local('Neue Haas Grotesk Display Pro 26 XThin Italic'), local('NeueHaasDisplay-XThinItalic'),
    url('NeueHaasDisplay-XThinItalic.eot?#iefix') format('embedded-opentype'),
    url('NeueHaasDisplay-XThinItalic.woff') format('woff'),
    url('NeueHaasDisplay-XThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
