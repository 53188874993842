.workflowModal .tablemodal {
  height: 380px;
}

.workflowModal .tablemodal tr td:first-child span {
  text-transform: uppercase;
}

.content-fix {
  min-height: 400px;
  max-height: 400px;
  overflow: hidden;
}

.StructureIModule .bitaCard {
  height: 170px !important;
  min-height: 170px !important;
  width: 28%;
  margin: 10px;
}

/* .StructureIModule .bitaCard .bitaCardTitle {
  font-size: 21px !important;
} */

/* .StructureIModule .bitaCard ~ span {
  font-size: 28px;
} */

.StructureIModule .cardsContainerBig {
  flex-wrap: wrap;
  justify-content: center;
}
.StructureIModule .searchFilterOptions {
  margin-top: 0px !important;
  height: 50px;
  align-items: center;
  display: flex;
}

.StructureIModule .bitaSearchBar > div > div {
  margin-bottom: 0px !important;
}
.StructureIModule {
  height: auto !important;
}
.rruniverse .tablemodal > div > div:nth-child(1) {
  display: none;
}

.FactsheetModule .simplebar-track.simplebar-vertical {
  right: 5px !important;
}

.rruniverse .tablemodal > div .content-Widget {
  margin-bottom: 0px;
  padding: 10px 18px 0px 18px;
}
@media (max-width: 1370px) {
  .StructureIModule {
    height: 90% !important;
    max-height: 780px;
  }
  .StructureIModule section .runBacktestStructured {
    position: fixed;
    bottom: 2px !important;
  }

  .StructureIModule section {
    min-height: calc(100% - 150px) !important;
    max-height: calc(100% - 150px) !important;
  }
  .StructureIModule .content-fix {
    min-height: calc(100% - 80px) !important;
    max-height: calc(100% - 80px) !important;
    height: calc(100% - 80px) !important;
  }

  .StructureIModule .searchInstrumentsModal {
    min-height: calc(100% - 40px) !important;
    max-height: calc(100% - 40px) !important;
    height: calc(100% - 40px) !important;
  }
  .StructureIModule .sectionnotText {
    padding-top: 20px;
    min-height: calc(100% - 20px) !important;
    max-height: calc(100% - 20px) !important;
  }
  .StructureIModule .content_parameters {
    height: 260px !important;
  }
  .StructureIModule .sectionnotText button {
    margin-top: 10px;
  }
}

@media (min-width: 1680px) {
  .StructureIModule {
    position: relative;
    width: 1300px !important;
    height: 750px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  }
  .StructureIModule .content-fix {
    min-width: 650px !important;
    max-height: 550px !important;
    height: 550px !important;
  }
}
