.pageHeading {
  display: flex;
  padding: 10px 0 40px 0;
  justify-content: space-between;
  align-items: center;
}

.pageHeading {
  display: flex;
  padding: 10px 0 40px;
  justify-content: space-between;
  align-items: center;
}
.pageTitle {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.4px;
  display: flex;
  align-items: center;
}

.parametrizationTitle {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.parametrizationTitle > img {
  margin-right: 15px;
}

.availableRules {
  background: #3b3f44;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
}

.parametrizationTitle {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.cards {
  display: flex;
  flex-wrap: wrap;
}

.pageTitle {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.4px;
  display: flex;
  align-items: center;
}

.pageTitle svg {
  margin-right: 15px;
}
.pageInitial {
  background: #fff;
  color: #2962ff;
  font-size: 28px;
  border-radius: 6px;
  display: inline-block;
  padding: 0 8px;
  margin-right: 10px;
}

.content {
  background: #3b3f44;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
}
.contentHeading {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.contentHeading img {
  margin-right: 10px;
}

.contentSwitch {
  font-weight: 500;
  display: flex;
}
.contentSwitch > * {
  margin: 0 15px !important;
}

.contentCards {
  font-weight: 500;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contentCards > div:not(:first-child) {
  margin-bottom: 20px;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  color: white;
  margin-right: 15px;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 17px;
  height: 17px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: transparent;
}

[type='radio']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 17px;
  height: 17px;
  border: 1px solid #2962ff;
  border-radius: 100%;
  background: transparent;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 9px;
  height: 9px;
  background: #2962ff;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
