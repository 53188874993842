.firstRowText {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.27px;
  line-height: 17px;
  text-align: justify;
}

.firstRowContainer {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;

  button {
    margin-top: 10px;
  }
}

.titleIconContainer {
  display: flex;
  align-items: center;
  width: 80%;
  min-width: 80%;
  & :first-child {
    margin-right: 10px;
  }
}

.previewButtonContainer {
  display: flex;
}

.previewIconContainer {
  margin-right: 10px;
}

.firstRowIcon {
  background: #11369d;
  color: #fff;
  display: inline-block;
  padding: 5px 10px;
  vertical-align: middle;
  border-radius: 6px;
}
