.ActivateCalculation
  .builderWidget:nth-last-child(1)
  > div:nth-last-child(1)
  > div:nth-last-child(2) {
  padding: 0px;
}

.ActivateCalculation .builderWidget > div:nth-last-child(1) {
  align-items: end;
}

.ActivateCalculation .builderWidget > div:nth-last-child(1) .builderInputComponentContainer {
  margin-bottom: 0px;
  margin-right: 10px;
}

.ActivateCalculation .radioHolder {
  width: 410px;
  margin-bottom: 20px;
}

.content_action_basket {
  height: 400px;
}

.ActivateCalculation .content {
  background-color: transparent !important;
}

.ActivateCalculation .content .content {
  background-color: #2a2d31 !important;
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 10px;
}

.ActivateCalculation .text-title {
  text-align: center;
  font-size: 25px;
  margin: 20px auto;
}

.ActivateCalculation .inputStyle {
  margin-top: 0px !important;
}

.ActivateCalculation .Start .dropdownLine .dropdownField {
  margin-top: 20px;
}
