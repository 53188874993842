@import '../../sass/Variables.scss';

.tableTitle {
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.tableContainer {
  display: flex;
}

@media (max-width: 700px) {
  .tableContainer {
    flex-direction: column;
  }
}
