@import '../../../sass/Variables.scss';

.popUpContainer {
  font-size: 12px;
  position: relative;
}

.popup {
  padding-top: 20px;
  margin-left: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  max-height: 500px;

  .image {
    max-height: 267px;
    height: 35vh;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.popUpContainer .closebutton {
  top: -29px;
  right: -15px;
}
.popUpContainer header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popUpContainer header > div {
  display: flex;
  align-items: center;
}

.popUpContainer .builderInputComponentContainer {
  width: 50% !important;
  display: inline-block;
  > div {
    width: 95% !important;
    margin-left: 0px !important;
  }
}

.popUpContainer .builderInputComponentContainer:nth-child(1) {
  width: 100% !important;
  > div {
    width: 100% !important;
  }
}

.popUpContainer .builderInputComponentContainer div {
  width: 100%;
  margin: 0px;
}

.popUpContainer .builderInputComponentContainer input {
  width: 100% !important;
  margin-top: 5px;
  margin-bottom: 21px;
}

.popup > div:nth-child(3) {
  margin-bottom: 0px;
  //height: 137px;
}

.popup .builderSearchBar {
  width: 48% !important;
  margin-top: -10px;
}
.popup .builderSearchBar > div > div {
  width: 100% !important;
}

.popup .builderSearchBar .externalTitle {
  color: #2962ff !important;
  font-weight: 600 !important;
  margin-bottom: 16px;
}

.popup .builderSearchBar > div {
  display: block;
}
.popUpContainer .builderInputComponentContainer span {
  color: #2962ff !important;
  font-weight: 600 !important;
}

.popUpContainer .builderInputComponentContainer input {
  background-color: transparent !important;
}

.popUpContainer .bitabutton {
  color: #2962ff !important;
  background: #fff !important;
  width: 43% !important;
  margin-bottom: 16px;
  margin-top: 11px;
}

.popUpContainer .popup .whiteButton {
  color: #2962ff !important;
  background: #fff !important;
  position: relative;
  margin: auto;
  margin-top: 50px;
  min-width: 120px;
}
.popUpContainer .bitabutton:hover {
  background-image: linear-gradient(90deg, #ffffff 0%, #d5d5d5 97%, #d4d4d4 100%) !important;
}

.popUpContainer .bitabutton ~ div {
  width: 100%;
}

.popUpContainer .popup > .bitabutton:last-child {
  position: relative;
  color: #fff !important;
  margin: auto;
  margin-top: 41px !important;
  background: linear-gradient(270deg, #2962ff 0%, #0039cb 100%) !important;
}
.popUpContainer .uploadButton {
  margin-top: 10px !important;
  color: #fff !important;
  background: linear-gradient(270deg, #2962ff 0%, #0039cb 100%) !important;
}

.popUpContainer .bitabutton:nth-last-child(1):hover {
  background-image: linear-gradient(270deg, #768fff 0%, #2962ff 100%) !important;
}
.popUpContainer .additionalSettingButton .bitabutton:nth-last-child(1) {
  color: #2962ff !important;
  background: #fff !important;
  width: 100% !important;
  margin-top: 0px !important;
}

.popUpContainer .additionalSettingButton .bitabutton:nth-last-child(1):hover {
  background-image: $background-button-primaryWhite-hover !important;
  border-radius: 3.15px !important;
}

.popUpContainer .dropdownField {
  margin-top: -10px;
  min-width: 220px;
  color: #2962ff;
  font-weight: 600 !important;
  margin-bottom: 21px;
  margin-left: 0 !important;
  margin-right: 0;
}

.popUpContainer .dropdownContainer {
  margin-top: 16px;
}

.popUpContainer .dropdown__menu {
  color: white !important;
}
.popUpContainer .barsearch__menu-list {
  max-height: 260px !important;
}

.popUpContainer .dropdownField > div {
  width: 100% !important;
  margin-top: 10px;
}

.popUpContainer .builderSwitchComponentTop {
  display: block !important;
  margin-top: 0px;
  margin-bottom: 16px;
}

.popUpContainer .builderSwitchComponentTop > div {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.popUpContainer .no__progress__value__indicator ~ .bitabutton ~ .bitabutton {
  width: 43% !important;
  margin-bottom: 16px !important;
  margin-right: 0px !important;
  margin-top: 11px !important;
}

.popUpContainer .react-sweet-progress ~ .bitabutton {
  width: 35% !important;
  margin-left: 10% !important;
}

.popUpContainer .react-sweet-progress ~ .bitabutton ~ .bitabutton {
  width: 35% !important;
  margin-top: 0px !important;
  margin-right: 10% !important;
  margin-left: 0px !important;
}

.popUpContainer .builderSwitchComponentTop b {
  font-weight: 600 !important;
}

.popUpContainer .builderSwitchComponentTop > b {
  color: #2962ff;
}

.popUpContainer .builderSwitchComponentTop > div .BitaSwitch {
  margin-left: 10px;
  margin-right: 10px;
  height: 20px;
}

.popUpContainer .calendarText {
  margin-top: 0px !important;
}

.popUpContainer .CalendarRange {
  width: 100% !important;
  margin-bottom: 8px;
}

.popUpContainer .CalendarDate {
  min-width: 220px;
  margin-right: 0px;
  margin-bottom: 7px !important;
  box-sizing: border-box;
  padding-bottom: 15px;
}

.CalendarDate ~ .CalendarDate {
  width: 48% !important;
}

.popUpContainer .CalendarDate ~ .dropdownLine {
  width: 48% !important;
  margin-top: 5px;
}

.popup > div:nth-child(5) .calendar {
  margin-left: -104px;
}

.popUpContainer .builderToggleGroup {
  width: 50% !important;
  height: 170px;
}
.popUpContainer .CalendarDate > div:nth-child(1) {
  margin-bottom: 14px !important;
}

.popUpContainer .CalendarDate input {
  margin-bottom: 0px !important;
}
.popUpContainer header:first-of-type + div {
  width: 100% !important;
}

.popUpContainer header:first-of-type + div > input {
  width: 100% !important;
  margin-top: 10px;
}

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  color: white;
  margin-right: 15px;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 17px;
  height: 17px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: transparent;
}

[type='radio']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 17px;
  height: 17px;
  border: 1px solid #2962ff;
  border-radius: 100%;
  background: transparent;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 9px;
  height: 9px;
  background: #2962ff;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.popUpContainer img {
  width: 50%;
  height: 35vh;
  max-height: 250px;
  margin-top: 30px !important;
}

.popUpContainer > .image ~ button,
.popUpContainer > .image ~ button ~ .bitabutton {
  width: 150px !important;
}

.popUpContainer > .image ~ button {
  margin-left: 50px;
}

.popUpContainer > .image ~ button ~ .bitabutton {
  position: relative;
  margin-right: 20px;
  margin-left: 42px !important;
}

.popUpContainer .react-sweet-progress {
  width: 60% !important;
  margin: auto;
  margin-top: 10px;
  padding: 20px;
  margin-left: 20%;
  display: flex !important;
}

.popUpContainer .react-sweet-progress-line {
  width: 80% !important;
}

.popUpContainer .react-sweet-progress-symbol {
  color: white !important;
}

.popUpContainer .textBuilder {
  font-size: 14px;
  margin-left: 0px;
}

.popUpContainer .dropdown__placeholder:before {
  content: 'un';
  color: transparent;
  background-image: url('../../../static/img/Icons/search.svg');
  background-repeat: no-repeat;
  width: 15px;
  height: 8px;
  margin-top: 5px;
}

.content-instrument_selection {
  align-items: center;
  background: #3b3f44 !important;
  padding: 18px;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 30px;
}

.content-instrument_selection .barsearch__control {
  margin-bottom: 0px !important;
}

.content_parameters .optimizationInputComponentContainer > div {
  margin-left: 0px !important;
}
.content_parameters .optimizationInputComponentContainer > div > div > div {
  align-items: center;
}
.content_parameters .optimizationInputComponentContainer > div > div span {
  padding-top: 0px !important;
}

.Leverage .dropdownField {
  text-transform: none !important;
}

.Leverage .titleInput {
  display: none;
}

.instrument_selection_titles {
  text-transform: uppercase;
  font-size: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.instrument_selection_titles > div {
  display: flex;
  align-items: center;
}

.instrument_selection_titles > div > svg {
  width: 20px;
  height: 14px;
  margin-right: 5px;
  margin-bottom: 1px;
}
.FactsheetModule .title {
  font-weight: bold;
}

.FactsheetModule .react-datepicker-component .react-datepicker {
  margin-left: 160px;
  margin-top: -55px;
}
.Deleverage,
.Reverse {
  display: flex;
  flex-direction: inherit;
  flex-wrap: wrap;
}

.Reverse .builderInputComponentContainer input,
.Deleverage .builderInputComponentContainer input {
  margin-top: 0px;
}

.Reverse .builderInputComponentContainer span,
.Deleverage .builderInputComponentContainer span {
  margin-top: -5px;
}
.builderInputComponentContainer span {
  padding-top: 0px !important;
}

.Deleverage > div:nth-child(1),
.Deleverage > div:last-child,
.Reverse > div:nth-child(1),
.Reverse > div:last-child {
  width: 100%;
}

.Deleverage > div:nth-child(1) > div,
.Deleverage > div:last-child > div,
.Reverse > div:nth-child(1) > div,
.Reverse > div:last-child > div {
  width: 100%;
}

.Deleverage > div:nth-child(2) > div > div,
.Deleverage > div:nth-child(3) > div > div,
.Reverse > div:nth-child(2) > div > div,
.Reverse > div:nth-child(3) > div > div {
  display: flex;
  flex-direction: column;
}

.Deleverage > div:nth-child(3) > div > div,
.Reverse > div:nth-child(3) > div > div {
  margin-top: 10px;
}

.Deleverage > div:nth-child(3) > div > div span,
.Reverse > div:nth-child(3) > div > div span {
  width: 280px;
}

.Deleverage .titleInput,
.Reverse .titleInput {
  width: 300px;
}

.Decrement .dropdownLine span {
  padding-top: 0px;
}

.Decrement .builderToggleGroup .dropdownLine span {
  padding-top: 20px;
}
.Reset .optimizationInputComponentContainer {
  width: 500px !important;
}

.iconCardSelect svg {
  position: initial;
  left: 0%;
  top: 0px;
  width: 70px;
  transform: scale(1.1);
  height: 90px;
  opacity: 1;
  transition-duration: 1s;
}

.scrollParameters {
  height: 360px;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.scrollParameters .dropdownLine {
  font-size: 13px !important;
}

.scrollParameters .simplebar-track.simplebar-vertical {
  top: 0;
  right: 0 !important;
  width: 11px;
}

.close_modal_x {
  position: absolute;
  top: -15px;
  /* z-index: 9000; */
  right: -15px;
}

.close_modal_x .dropdown__control {
  display: none;
}

.close_modal_x .workflow_modal__close_options {
  position: absolute;
  right: 25px;
  top: 36px;
  transform: scale(0.7);
  z-index: 200;

  .dropdown__option:first-child {
    position: relative;
    top: 0;
  }
  .dropdown__menu-list {
    background: #4b5158;

    .dropdown__option {
      background: #4b5158;
    }
  }
}

.popUpContainer .close_modal_x .workflow_modal__close_options {
  right: 25px;
  top: 12px;
  transform: scale(0.9);
  z-index: 200;
}

.close_modal_x .workflow_modal__close_options__hidden {
  display: none;
}

.close_modal_x {
  .dropdown__menu-list {
    background: #4b5158;

    .dropdown__option {
      background: #4b5158;
    }
  }
}

.searchInstrumentsModal .simplebar-track.simplebar-vertical {
  top: 0;
  right: 0px !important;
  width: 11px;
}

.StructureIModule .content_parameters .simplebar-track.simplebar-vertical {
  right: 0px !important;
}

.popup-structured .builderToggleGroup {
  display: none;
}

.popup-structured .builderDropdown {
  display: none;
}

.popup-structured .textBuilder {
  display: none;
}
.popup-structured .popup {
  max-height: 400px;
}

.contentFactsheet {
  .CalendarDate {
    margin-top: -10px;
    margin-bottom: 10px;
    width: 150px;
  }
  .inputFs {
    margin-top: 5px;
    color: #fff;
    border: none;
    border-bottom: 1px solid #c7c7c7;
    letter-spacing: 1.54px;
    padding-left: 10px;
    font-size: 13px;
    padding-bottom: 3px;
  }
  .dropdownContainer {
    margin-bottom: 10px !important;
  }
  .dropdown__control {
    height: 30px;
    min-height: 30px !important;
    max-height: 30px !important;
  }
  .dropdown__single-value {
    font-weight: 500;
    top: 45% !important;
    color: #fff !important;
    font-size: 12px;
  }
  .react-datepicker-component .react-datepicker-input input {
    padding-left: 10px;
  }

  .dropdown__indicators {
    margin-top: -2px;
  }

  .react-datepicker-input {
    height: 30px;
  }
}

.popup-structured .builderSearchBar {
  width: 100% !important;
  > div {
    width: 48%;
  }
  .externalTitle {
    margin-bottom: 25px;
    margin-top: 10px;
  }
}
@media (min-width: 1680px) {
  .StructureIModule .searchInstrumentsModal {
    height: 570px !important;
  }
  .StructureIModule .content_parameters {
    height: 380px !important;
  }
  .StructureIModule .content_parameters ~ button {
    margin-top: 15px !important;
  }
  .StructureIModule .simplebar-track.simplebar-vertical {
    right: 5px;
  }
}

@media (max-height: 800px) {
  .popup {
    .image {
      max-height: 267px;
      margin: auto;
      height: 30vh;
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 50%;
        height: 30vh;
        margin: auto;
        margin-top: 15px !important;
      }
    }
  }
  .popUpContainer .react-sweet-progress {
    padding: 10px;
  }
}
