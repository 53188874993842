@import '../../sass/Variables.scss';

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: $background-color-secondary;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  color: $input-text-color-secondary;
}

.box {
  height: 570px;
  width: 555px;
  border: 1px solid $text-color-main;
  padding: 54px 68px 46px 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
}

.rememberme label {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-weight: 600;
  letter-spacing: 1.39px;
  color: #fff !important;
}
.logo {
  width: 100px;
  height: 100px;
}

.form {
  display: flex;
  flex-direction: column;
  letter-spacing: 1.54px;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  margin: 30px 0;
  font-size: 13.5px !important;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;

  & input[type='text'],
  input[type='password'] {
    height: 45px;
    letter-spacing: 1.54px;
    color: $input-text-color-secondary;
    background-color: transparent !important;
    border-bottom: solid 1px $input-bottom-border-color;
    padding-left: 0px !important;
  }

  & input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $input-text-color-secondary;
    opacity: 1; /* Firefox */
  }

  & input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $input-text-color-secondary;
  }

  & input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $input-text-color-secondary;
  }
}

.centered {
  display: flex;
  justify-content: center;
}

.rowpass {
  position: relative;
}

.forgot {
  position: absolute;
  right: 3px;
  top: 11px;
  font-weight: $font-weight-light;
  cursor: pointer;
}

.password {
  width: 100%;
}

.loginButton {
  color: $text-color-main;
  height: 32px;
  width: 124px;
  letter-spacing: 1.37px;
  border-radius: 4px;
  font-size: 12px;
  background: linear-gradient(270deg, $text-color-tertiary 0%, $text-color-quaternary 100%);
  font-weight: $font-weight-semibold;
}
.separator {
  width: 82px;
  border-top: 1px solid $input-text-color;
}

.information {
  width: 355px;
  text-align: center;
  margin-top: 28px;
  letter-spacing: 1.2px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.informationText {
  font-size: 12px;
}

.informationLink {
  line-height: 23px;
  color: $text-color-secondary;
  font-weight: $font-weight-semibold;
}
