.filterGroupButton {
  background: #34373c;
  margin-right: 10px;
  margin-bottom: 10px;
  width: auto !important;
  padding: 0 20px !important;
}

.filterGroupButtonActive {
  background: #2962ff;
}

.filterGroupAddButton {
  background: #fff;
  color: #2962ff;
  width: auto !important;
  display: inline-block !important;
  padding: 0 10px !important;
}
.filterGroupAddButton:hover {
  color: #fff !important;
}
.actionButtons {
  text-align: right;
  margin-top: 14px;
}

.pageHeading {
  display: flex;
  padding: 10px 0 40px 0;
  justify-content: space-between;
  align-items: center;
}
.pageTitle {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.4px;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  svg {
    margin-right: 15px;
  }
}
.pageInitial {
  background: #fff;
  color: #2962ff;
  font-size: 28px;
  border-radius: 6px;
  display: inline-block;
  padding: 0 8px;
  margin-right: 10px;
}

.content {
  background: #3b3f44;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
}
