.customerService {
  div .title {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    svg {
      margin-right: 10px;
      width: 20px;
      height: 20px;
    }
  }
  button {
    margin-top: 20px;
  }
  .clientSupport {
    margin-top: 40px;
    > div {
      width: 180px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      svg {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;
      }
      a {
        color: #2962ff;
        font-weight: 500;
      }
    }
    > div:nth-child(2) {
      width: 40px;
      text-align: center;
      margin-top: 40px;
    }
  }
  .contentCardDownload {
    justify-content: flex-start !important;
    margin-top: 20px;
    margin-bottom: 10px;
    transition-duration: 1s;
    flex-wrap: wrap;
    > div:hover {
      background: linear-gradient(270deg, #2962ff 0%, #4aa9db 100%);
      transform: scale(1.08);
    }
    > div {
      min-width: 90px;
      width: calc(30% - 10px);
      padding: 10px;
      margin: 5px;
      height: 140px;
      border-radius: 5px;
      background: linear-gradient(270deg, #2962ff 0%, #26276b 100%);
      transition-duration: 1s;

      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        div {
          font-weight: 500;
          font-size: 10px;
        }
      }
      svg {
        width: 30px;
        height: 30px;
        margin-bottom: 15px;
      }
      .version {
        margin-top: 15px;
        font-weight: 300;
        font-size: 10px;
      }
    }
  }
}
