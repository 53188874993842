.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.settings .dropdownLine {
  display: contents;
}

.container > div {
  width: 100%;
  flex-shrink: 0;
}
.container > div:nth-last-child(3) {
  width: 100%;
}

.container > div:nth-last-child(2),
.container > div:nth-last-child(1) {
  width: 49%;
}
.container > div:nth-last-child(1) button {
  margin-bottom: 10px;
}

@media (max-width: 940px) {
  .container > div:nth-last-child(2),
  .container > div:nth-last-child(1) {
    width: 100%;
    min-height: 300px;
  }
}

@media (max-width: 600px) {
  .container > div:nth-last-child(2),
  .container > div:nth-last-child(1) {
    min-height: 340px;
  }
}
