.headerpath {
  background: #2962ff;
  color: #fff;
  font-weight: bold;
  font-size: 21px;
  margin-left: 10px;
  display: inline-block;
  padding: 14px 10px;
  border-radius: 4px;
  margin-right: 0px;
  height: 50px;
}

.container {
  background: #3b3f44 !important;
  border-radius: 8px;
  // padding-right: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.viewData {
  font-size: 12px;
  background: #a7a7aa;
}

.subtitle {
  width: 100%;
  color: #2962ff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 11px;
  display: flex;
}

.smallLetters {
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 10px;
  display: flex;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  outline-style: none !important;
  background-color: #fff;
  color: #2962ff;
  border-radius: 2.55px;
  font-size: 12px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  letter-spacing: 1.37px;
  font-weight: 500;
  width: 160px !important;
  height: 35px !important;
  padding: 8px 44px;
  margin: 0 15px 0px 0px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-image: linear-gradient(90deg, #ffffff 0%, #d5d5d5 97%, #d4d4d4 100%);
  border-radius: 3.15px;
}

.nameFile {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #a7a7aa;
  font-size: 13px;
  margin-top: 10px;
}

.generatedTable {
  margin-bottom: 10px;
  border-radius: 5px;
  th,
  tr,
  td {
    text-align: center;
    padding: 10px;
    border-bottom: 1px solid;
  }
}

.generatedTableValues {
  border-bottom: 1px solid;
}

.content {
  height: calc(100% - 60px);
  background-color: #2a2d31 !important;
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 25px;
}

.applyButton {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.dataUpload {
  background: #3b3f44 !important;
  padding-top: 18px;
  padding-bottom: 18px;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contentTemplate {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-left: 18px;
  flex-wrap: wrap;
}

.applicationPointContainer {
  margin-left: 18px;
}

.contentTemplateUpload {
  width: 100%;
  max-width: 560px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  border: 2px solid gray;
  padding: 18px;
  margin-right: 18px;
}

.compositionPortfolio {
  background: #3b3f44;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
}

.compositionPortfolioTitleView {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.compositionPortfolioTitle {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid gray;
}
.contentNoMetadata {
  width: 20%;
  padding-right: 40px;
}
.tableNoMetadata {
  margin-top: 20px;
  border-top: 1px solid gray;
  padding-top: 30px;
  font-size: 10px;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    > div:nth-child(1) {
      text-transform: uppercase;
      font-weight: bold;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.buttonUpload {
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  outline-style: none !important;
  background-color: #fff;
  color: #2962ff;
  border-radius: 2.55px;
  font-size: 12px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  letter-spacing: 1.37px;
  font-weight: 500 !important;
  width: 160px !important;
  height: 35px !important;
  padding: 8px 44px;
  margin: 0 15px 0px 0px;
}
@media (max-width: 1797px) {
  /* Estilos que se aplicarán cuando el ancho de la pantalla sea 1797px o menos */
  .contentTemplateUpload {
    max-width: 380px;
  }
}

@media (max-width: 1346px) {
  /* Estilos que se aplicarán cuando el ancho de la pantalla sea 1346px o menos */
  .contentTemplateUpload {
    max-width: 200px;
  }
}

.compositionPorfolio {
  background: #3b3f44;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
}

.compositionPorfolioTitle {
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid gray;
}
