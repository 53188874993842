.headerpaths {
  font-size: 21px;
  border-bottom: 1px solid #a7a7aa;
  padding: 10px 0 20px 0;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  min-height: 67px;
}

.modalContent {
  position: relative;
  width: 1100px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  padding-bottom: 10px;
}

.modalSmall {
  width: 650px;
  background-color: #2a2d31 !important;
}

.customRadio {
  display: inline-block;
  font-weight: 500;
  margin-right: 40px;
}

.closeModalButton {
  font-size: 30px;
  cursor: pointer !important;
  font-weight: bold;
  display: block;
  position: absolute;
  top: -15px;
  right: 0;
  padding: 20px;
}

.headerpaths span {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  margin-right: 0px;
}
.headerpaths span:not(:first-child):not(:last-child)::after {
  color: #0039cb;
  content: ' ';
  margin-left: 18px;
  font-size: 30px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #0039cb;
  display: block;
  float: right;
  margin-top: 8px;
}

.componentText {
  text-align: center;
  font-size: 25px;
  margin: 20px 0;
}

.headerpaths span:first-child {
  color: #0039cb;
  background: #fff;
  font-weight: bold;
}

.headerpaths span:last-child {
  background: #2962ff;
  color: #fff;
  font-weight: bold;
  height: 42px;
  margin-left: 10px;
}

.headerpaths span:last-child:hover {
  background-image: linear-gradient(270deg, #768fff 0%, #2962ff 100%);
}

.cardsContainer {
  display: flex;
  width: 76%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}

.cardsContainerBig {
  display: flex;
  width: 97%;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}

.cardSeparator {
  display: inline-block;
  font-size: 34px;
  text-align: center;
  width: 80px;
}

.cardComponent {
  height: 200px;
}

.searchTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.searchTop .searchFilterOptions {
  margin-top: -10px;
}

.searchFilterOptions {
  text-align: left;
}

.searchInput {
  text-align: left;
  width: 400px;
}

.searchInput > div > div > div:nth-last-child(1) > div > div:hover {
  background: white !important;
  color: #2a2d31 !important;
  font-weight: 500;
}

.searchResultsContainer {
  display: block;
  font-size: 14px;
  margin-right: 9px;
}

.searchResultColumn {
  color: #fff;
}

.searchResultHeadColumn {
  text-transform: uppercase;
}

.searchResultColumn:first-child {
  width: 45%;
}

.searchResultColumn:nth-child(2) {
  width: 20%;
}

.searchResultColumn:nth-child(3) {
  width: 20%;
}

.searchResult {
  border-radius: 6px;
  background: #4b5158;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.searchResult b {
  display: inline-block;
  margin-right: 10px;
}

.deleteResult {
  position: static !important;
  width: auto !important;
  font-weight: bold;
  padding: 4px 16px;
}

.applyButton {
  width: auto !important;
  padding: 10px 140px;
  margin: auto;
  display: block;
  height: auto !important;
}

.selectedRowContainer {
  display: flex;
  flex-direction: row;
}
.selectedRowIcon {
  background: #fff;
  color: #0039cb;
  display: block;
  padding: 5px 10px;
  border-radius: 6px;
}

.selectedRowIcon span {
  display: block;
  font-size: 10px;
}

.selectedRowIcon span:first-child {
  font-size: 20px;
}

.selectedRowContents {
  padding-left: 10px;
}
.selectedRowLine {
  background: transparent;
}
.selectedRowLine b {
  display: inline-block;
  padding-right: 5px;
}

.actionButtonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.actionButton {
  padding: 10px 80px;
  display: block;
  height: auto !important;
  width: auto !important;
}

.popUpContainer {
  width: 510px;
  margin: auto;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
}

.popUpContainer > * {
  margin: 10px 10px 10px 0;
  display: inline-block;
}

.popUpContainer .headerpaths span:last-child {
  background: transparent;
}

.popUpContainer .headerpaths {
  width: 100% !important;
  padding-bottom: 10px !important;
  display: flex;
}

.popUpContainer input {
  width: 100% !important;
}

.rebalancingselectedTitle {
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rebalancingselectedTitle > div {
  display: flex;
  align-items: center;
}
.optionTitle {
  margin-left: 10px;
  font-weight: bold;
}

.contentFirstOption {
  display: flex;
  padding-top: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 11px;
}

.contentFirstOption span {
  color: #2962ff;
  margin-right: 6px;
  font-weight: bold;
}

.contentFirstOption button {
  margin-top: 10px;
}
.contentFirstOption label {
  text-transform: capitalize;
}

.contentUpload {
  display: flex;
  align-items: center;
  height: 27px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  outline-style: none !important;
  background-image: linear-gradient(270deg, #2962ff 0%, #0039cb 100%);
  color: #fff;
  border-radius: 2.55px;
  font-size: 12px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  letter-spacing: 1.37px;
  font-weight: 500;
  text-transform: capitalize;
  width: auto !important;
  height: 27px !important;
  padding: 5px 60px;
  margin: 0px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-image: linear-gradient(90deg, #ffffff 0%, #d5d5d5 97%, #d4d4d4 100%);
  border-radius: 3.15px;
  color: #2962ff;
}

.nameFile {
  width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 11px;
  margin-left: 10px;
  height: 14px;
  font-weight: 200;
  text-transform: initial;
}

.contentSwitcher {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  margin-bottom: 8px;
}

.contentSwitcherDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.contentSwitcher:nth-child(1) {
  margin-top: 5px;
}

.contentSwitcher:last-child {
  margin-bottom: 0px;
}

.titleFactsheet {
  color: #2962ff;
  font-weight: 700;
  font-size: 10px;
  margin-bottom: 5px;
}

@media (max-width: 1370px) {
  .deleteResult {
    position: static !important;
    width: auto !important;
    font-weight: bold;
    padding: 4px 16px;
  }
}

/* --------------- */

.workflowModal .tablemodal {
  height: 380px;
}

.workflowModal .tablemodal tr td:first-child span {
  text-transform: uppercase;
}

.content-fix {
  min-height: 400px;
  max-height: 400px;
  overflow: hidden;
}

.StructureIModule .bitaCard {
  height: 170px !important;
  min-height: 170px !important;
  width: 28%;
  margin: 10px;
}

/* .StructureIModule .bitaCard .bitaCardTitle {
    font-size: 21px !important;
  } */

/* .StructureIModule .bitaCard ~ span {
    font-size: 28px;
  } */

.StructureIModule .cardsContainerBig {
  flex-wrap: wrap;
  justify-content: center;
}
.StructureIModule .searchFilterOptions {
  margin-top: 0px !important;
  height: 50px;
  align-items: center;
  display: flex;
}

.StructureIModule .bitaSearchBar > div > div {
  margin-bottom: 0px !important;
}
.StructureIModule {
  height: auto !important;
}
.rruniverse .tablemodal > div > div:nth-child(1) {
  display: none;
}

.FactsheetModule .simplebar-track.simplebar-vertical {
  right: 5px !important;
}

.rruniverse .tablemodal > div .content-Widget {
  margin-bottom: 0px;
  padding: 10px 18px 0px 18px;
}
@media (max-width: 1370px) {
  .StructureIModule {
    height: 90% !important;
    max-height: 780px;
  }
  .StructureIModule section .runBacktestStructured {
    position: fixed;
    bottom: 2px !important;
  }

  .StructureIModule section {
    min-height: calc(100% - 150px) !important;
    max-height: calc(100% - 150px) !important;
  }
  .StructureIModule .content-fix {
    min-height: calc(100% - 80px) !important;
    max-height: calc(100% - 80px) !important;
    height: calc(100% - 80px) !important;
  }

  .StructureIModule .searchInstrumentsModal {
    min-height: calc(100% - 40px) !important;
    max-height: calc(100% - 40px) !important;
    height: calc(100% - 40px) !important;
  }
  .StructureIModule .sectionnotText {
    padding-top: 20px;
    min-height: calc(100% - 20px) !important;
    max-height: calc(100% - 20px) !important;
  }
  .StructureIModule .content_parameters {
    height: 260px !important;
  }
  .StructureIModule .sectionnotText button {
    margin-top: 10px;
  }
}

@media (min-width: 1680px) {
  .StructureIModule {
    position: relative;
    width: 1300px !important;
    height: 750px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  }
  .StructureIModule .content-fix {
    min-width: 650px !important;
    max-height: 550px !important;
    height: 550px !important;
  }
}

/* ----------- */

.close_modal_x {
  position: absolute;
  top: -15px;
  /* z-index: 9000; */
  right: -15px;
}
