.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.closeModal {
  font-size: 30px;
  cursor: pointer;
  font-weight: 300;
  display: block;
  position: absolute;
  top: -20px;
  right: -5px;
  padding: 20px;
}

.bitaButtonCustom {
  margin: 0px 5px 5px 0px;
}

.paletteContainer {
  width: 40vw;
  height: 30vh;
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
  -webkit-transform-style: preserve-3d;
  transform: translate();
}
.paletteContainer span {
  color: white;
}

.paletteContainer input {
  background-color: white;
  text-transform: uppercase;
  width: 75px !important;
  text-align: center;
}

.modalFooter {
  display: flex;
  justify-content: center;
}

.modalDescription {
  text-align: justify;
  font-weight: normal;
  text-align: justify;
  color: white;
  font-size: 15px;
  letter-spacing: 1.71px;
}

.textArea {
  width: 100%;
  height: 100%;
  text-align: justify;
  padding: 15px;
  background-color: #575a5f !important;
  color: white;
  font-weight: normal;
  letter-spacing: 1.71px;
}

.textContainer {
  flex: 1;
  height: 100%;
}

.textContainer span {
  justify-content: flex-end;
  align-self: flex-end;
  font-size: 12px;
  font-weight: 400;
  display: flex;
}

.brandingTitle {
  color: #2962ff !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500 !important;
  font-size: 11px !important;
}

.widgetInput {
  background-color: unset;
  background: #4b5158;
  border-radius: 2.55px;
  font-size: 13px;
  min-width: 100px;
  max-width: 100%;
  height: 35px;
  line-height: 25px;
  overflow: hidden;
}
.widgetInput ~ label {
  top: 8px;
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  outline-style: none !important;
  background-color: #fff;
  color: #2962ff;
  border-radius: 2.55px;
  font-size: 12px;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  letter-spacing: 1.37px;
  font-weight: 500;
  width: auto !important;
  height: auto !important;
  padding: 6px;
  margin: 0 4px 4px 4px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-image: linear-gradient(90deg, #ffffff 0%, #d5d5d5 97%, #d4d4d4 100%);
  border-radius: 3.15px;
}

.companyInfoContainer {
  display: flex;
  flex-direction: row;
}

.companyInfoColumn {
  display: flex;
  flex-direction: column;
  width: 380px;
}

.logoPreviewContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 380px;
  text-align: center;
  border: 1px solid white;
  height: 220px;
  justify-content: center;
  align-items: center;
}

.logoPreviewContainer img {
  max-width: 360px;
  height: 210px;
}
.websiteContainer {
  margin: 20px 0px 0px 0px !important;
}

.multimediaContainer {
  width: 40vw;
  height: 30vh;
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
  -webkit-transform-style: preserve-3d;
  transform: translate();
}

.containerRange {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  label {
    margin-right: 15px;
    color: white !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500 !important;
    font-size: 11px !important;
  }
}
