@import '../../sass/Variables.scss';

.centralPanel {
  display: flex;
  flex: 1;
  height: calc(100vh - 60px);
}

.rightSidePanel {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}

.centralLeftPanel {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.leftSidePanel {
  height: 100vh;
  display: flex;
  background: $background-color-main;
  width: 240px;
  flex-shrink: 0;
  z-index: 9;
}

.pagePanel {
  background: $background-color-main;
  flex: 1;
  height: calc(100vh - 60px);
}

.subTopbar {
  height: 40px;
  border-radius: 4px;
  background-color: #2962ff;
  display: flex;
  margin: 0 18px;
  align-items: center;
  color: #ffffff;
  padding: 0 18px;
  font-size: 15.6px;
  font-weight: bold;
  letter-spacing: 2.01px;
  line-height: 23px;
  text-align: justify;
  text-transform: uppercase;
}

.summaryPanel {
  width: 140;
  height: calc(100vh - 60px);
  min-width: 140px;
  max-width: 140px;
  background: #1d1d1d;
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  width: 249px;
}

.pageLayout {
  display: flex;
  flex-direction: column;
  background-color: #1b1b1b;
  height: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  padding: 18px 18px 0px 18px;
}

@media (max-width: 768px) {
  .leftSidePanel {
    width: 180px;
  }
}
