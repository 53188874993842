@import '../../sass/Variables.scss';

.topbarMain {
  display: flex;
  background: $background-color-main;
  color: $text-color-main;
  min-height: 50px;
  align-items: center;
}

.topbarMain__right_options {
  justify-content: flex-end;
  margin-left: auto;
}

.topbarLeft {
  display: flex;
  padding: 5px 10px;
  height: 100%;
  align-items: center;
}

.topbarRight {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 11px;
  padding: 5px 10px;
  height: 100%;
}

.topbarSeparator {
  height: 60%;
  border: solid 0.5px $text-color-main;
}

.projectName {
  font-weight: $font-weight-semibold;
  font-size: 15px;
}

.topbarLastModification {
  font-weight: $font-weight-light;
  font-size: 12px;
}

.topbarButton {
  text-transform: uppercase;
  align-items: center;
  display: flex;
  margin-left: 20px;

  & svg {
    height: 16px;
    width: 16px;
    fill: $text-color-main;
  }

  & span {
    margin-left: 5px;
    font-size: 10px;
    font-weight: $font-weight-regular;
    color: $text-color-main;
  }
}

.topbarButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  align-items: center;
}

.popUpCloser {
  position: absolute;
  font-size: 30px;
  font-weight: bold;
  top: -10px;
  right: 0;
  font-size: 26px;
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
}

.popUpAvantiIcon {
  margin-right: 20px;
}
