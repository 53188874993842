.contentDragDrop {
  width: 100%;
  border: 2px dashed #bdc3c7;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
}

.inputDragDrop {
  display: none;
}
