@import './icons.scss';

/* CORRIGE EL PAR DE AñOS QUE SALEN EN EL BLOQUE PARA ELEGIR AñO YA QUE QUEDABAN ENCIMA DE FLECHAS DESPLAZAMIENTO */
.react-datepicker-button.button-label.fixed {
  margin-left: 124px;
  margin-top: 3px;
}

// PALETTE

$_gradientGrey: linear-gradient(#fff, #f2f4f7);
$_gradientDarkGrey: linear-gradient(#fff, #dfe3e8);
$_gradientAzure: linear-gradient(#2da1f8, #1789dd);
$_white: #fff;
$_paleGrey: #f0f3f8;
$_silver: #ced0da;
$_cloudyGrey: #b5c0ce;
$_coolGrey: #5c5e63;
$_darkGrey: #2a2d31;
$_cerulean: #2da1f8;
$_waterBlue: #1991eb;
$_strawberry: #db242c;
$_bita_blue: linear-gradient(270deg, #2962ff 0%, #0039cb 100%) !important;
$_bita_hover_blue: #0039cb;

// INPUT VARIABLES

$font-size: 12px !default;
$font-weight: 300 !default;
$font-weight-selected: bold !default;

$input-height: 36px !default;
$input-height-small: 32px !default;
$input-min-width: 150px !default;

$input-font-size: 13px !default;

$input-border-radius: 4px !default;
$input-border-color: $_silver !default;
$input-border-color-hover: $input-border-color !default;
$input-border-color-open: $_gradientGrey !default;

$input-background: $_darkGrey !default;
$input-background-hover: $_gradientDarkGrey !default;
$input-background-open: $_gradientDarkGrey !default;

$input-color: $_darkGrey !default;
$input-color-hover: $input-color !default;
$input-color-open: $input-color !default;
$input-color-has-value: $_darkGrey !default;

$input-placeholder-color: $_coolGrey !default;
$input-placeholder-color-hover: $input-placeholder-color !default;

$input-button-icon-color: $_cloudyGrey !default;
$input-button-icon-color-hover: $_coolGrey !default;
$input-button-icon-color-open: $input-button-icon-color !default;

$input-button-icon-size: 15px !default;

$input-button-background: transparent !default;
$input-button-background-hover: transparent !default;

$input-clear-button-icon-size: 13px !default;
$input-clear-button-color: $input-button-icon-color !default;
$input-clear-button-color-hover: $_strawberry !default;

$input-opacity-disabled: 0.5 !default;

// PICKER VARIABLES

$picker-width: 250px !default;

$picker-color: $_coolGrey !default;
$picker-color-hover: $_coolGrey !default; //este es el color de fuente de las fechas que son del mes anterior

$picker-background: $_darkGrey !default;
$picker-background-hover: $_darkGrey !default;

$picker-border-radius: 5px !default;
$picker-border-color: #d9dee3 !default;

$picker-box-shadow: 2px 2px 2px $_darkGrey !default;
$picker-arrow-size: 5px !default;

$picker-header-background: $_gradientAzure !default;
$picker-header-background-hover: rgba(0, 0, 0, 0.075) !default;
$picker-header-color: $_white !default;
$picker-header-border-color: $_cerulean !default;

$picker-current-background: $_darkGrey !default;
$picker-current-background-hover: darken($picker-current-background, 5%) !default;
$picker-current-color: $_silver !default;
$picker-current-color-hover: $picker-current-color !default;

$picker-selected-background: $_bita_blue !default;
$picker-selected-background-hover: $picker-selected-background !default;
$picker-selected-color: $_white !default;
$picker-selected-color-hover: $picker-selected-color !default;

$picker-disabled-background: $_darkGrey !default;
$picker-disabled-color: $_coolGrey !default;

$_bita-calendar-header: $_bita_blue !default;
// STYLE

.react-datepicker-input input {
  color: $_white !important;
  background: $_darkGrey;
  height: 35px;
  /* text-align: center; */
  border: solid 1px;
  border-radius: 10px 10px;
  padding: 12px;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

// DatePickerInput

.react-datepicker-component {
  position: relative;
  font-size: $font-size;
  font-weight: $font-weight;

  &.is-disabled {
    pointer-events: none;
    opacity: $input-opacity-disabled;
  }

  .react-datepicker {
    margin-left: 5px;
    margin-top: 5px;
    display: inherit;
  }

  .react-datepicker-input {
    position: relative;
    min-width: $input-min-width;
    height: $input-height;
    background: $input-background;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;

    &.is-small {
      height: $input-height-small;
    }

    input {
      width: 100%;
      height: 100%;
      background: transparent;
      border: none;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 60px;
      font-size: $input-font-size;
      color: $input-color;
      font-weight: 500;

      &:focus {
        outline: none;
      }

      @include placeholder() {
        color: $input-placeholder-color;
        font-weight: 600;
      }
    }

    .button-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;

      .input-button {
        margin: 0 10px;
        background: $input-button-background;
        font-size: $input-button-icon-size;
        border-radius: 0 $input-border-radius $input-border-radius 0;
        cursor: pointer;
        color: $input-button-icon-color;

        &:hover {
          background: $input-button-background-hover;
          color: $input-button-icon-color-hover;
        }
      }

      .clear-button {
        cursor: pointer;
        font-size: $input-clear-button-icon-size;
        color: $input-clear-button-color;

        &:hover {
          color: $input-clear-button-color-hover;
        }
      }
    }

    &:hover {
      /*input {
        color: $input-color-hover;

        @include placeholder() {
          color: $input-placeholder-color-hover;
        }
      }

      .button-wrapper .input-button {
        color: $input-button-icon-color-hover;
      }*/
    }

    &.is-open {
      input {
        color: $input-color-open;

        @include placeholder() {
          color: $input-color-open;
        }
      }

      .button-wrapper .input-button {
        color: $input-button-icon-color-open;
      }
    }

    &.has-value input {
      color: $input-color-has-value;
    }
  }
}

// DatePicker

.react-datepicker {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
  font-size: $font-size;
  font-weight: $font-weight;

  &.floating {
    position: absolute;
    z-index: 10000;
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
  }

  &.position-top {
    top: auto;
    bottom: 100%;
    margin-bottom: 5px;

    .react-datepicker-container {
      &::after,
      &::before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &::after {
        border-top-color: $picker-border-color;
        border-width: $picker-arrow-size;
        margin-left: -1 * $picker-arrow-size;
      }

      &::before {
        border-top-color: $picker-border-color;
        border-width: ($picker-arrow-size + 1);
        margin-left: -1 * ($picker-arrow-size + 1);
      }
    }
  }

  &:not(.position-top) {
    .react-datepicker-container {
      .react-datepicker-top {
        &::after,
        &::before {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &::after {
          border-bottom-color: $_white;
          border-width: $picker-arrow-size;
          margin-left: -1 * $picker-arrow-size;
        }

        &::before {
          border-bottom-color: $picker-border-color;
          border-width: ($picker-arrow-size + 1);
          margin-left: -1 * ($picker-arrow-size + 1);
        }
      }
    }
  }

  .react-datepicker-container {
    width: $picker-width;
    position: relative;

    .react-datepicker-top {
      text-align: center;
      background: $_bita-calendar-header;
      color: $picker-header-color;
      border-top: 1px solid $_white;
      border-left: 1px solid $_white;
      border-right: 1px solid $_white;
      border-top-left-radius: $picker-border-radius;
      border-top-right-radius: $picker-border-radius;

      .week-days {
        height: 30px;

        .week-day {
          cursor: default;
          font-weight: 400;
          font-size: 13px;
        }
      }

      .display {
        height: 30px;

        .react-datepicker-button {
          text-decoration: none;
          padding: 4px;
          text-align: center;
          font-size: 15px;
          letter-spacing: 0.5px;
          cursor: pointer;

          &.button-left {
            font-size: 13px;
            padding: 4px 16px;
            border-top-left-radius: $picker-border-radius;
          }

          &.button-right {
            font-size: 13px;
            padding: 4px 16px;
            border-top-right-radius: $picker-border-radius;
          }

          &:hover {
            background: $picker-header-background-hover;
            border-radius: 4px;
          }

          &.fixed:hover {
            background: transparent;
            cursor: default;
          }
        }
      }
    }

    .react-datepicker-body {
      border-left: 1px solid $picker-border-color;
      border-right: 1px solid $picker-border-color;
      border-bottom: 1px solid $picker-border-color;
      border-bottom-right-radius: $picker-border-radius;
      border-bottom-left-radius: $picker-border-radius;

      .react-datepicker-row {
        margin-top: 0;
        width: 100%;
        min-height: 25px;
        &:not(:last-child) {
          border-bottom: 1px solid $picker-border-color;
        }

        &:last-child .react-datepicker-picker {
          &:first-child {
            border-bottom-left-radius: $picker-border-radius;
          }

          &:last-child {
            border-bottom-right-radius: $picker-border-radius;
          }
        }

        .react-datepicker-picker {
          color: $picker-color;
          background: $picker-background;
          cursor: pointer;
          text-decoration: none;
          font-weight: 400;

          &:not(:last-child) {
            border-right: 1px solid $picker-border-color;
          }

          &.day {
            min-height: 30px !important;
          }

          &.month {
            min-height: 65px !important;
          }

          &.year {
            min-height: 65px !important;
          }

          &:hover {
            color: $picker-color-hover;
            background: $picker-background-hover;
          }

          &.selected {
            color: $picker-selected-color;
            background: $picker-selected-background;
            font-weight: $font-weight-selected;
          }

          &.current {
            /*font-weight: 600;*/
            color: $picker-current-color;
            background: $picker-current-background;

            &:hover {
              color: $_bita_hover_blue;
              font-weight: 600;
              background: $picker-selected-color; //hover de fecha
            }
          }

          &.selected.current {
            color: $picker-selected-color;
            background: $picker-selected-background;
          }

          &.disabled {
            cursor: default;
            color: $picker-disabled-color;
            background: $picker-disabled-background;

            &:hover {
              color: $picker-disabled-color;
              background: $picker-disabled-background;
            }
          }
        }
      }
    }
  }
}

/* OBSOLETE: was reemplaced by new calendar ===========================================================================*/
.DateRangePicker {
  display: inline-block;
  margin-bottom: 10px;
  padding: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid white;
  border-radius: 3px;
}

.DateRangePicker__Legend {
  color: white;
  font-size: 14px;
  line-height: 16px;
  list-style-type: none;
  margin: 0px 0px 0px 10px;
  padding: 0;
}
.DateRangePicker__LegendItem {
  display: inline-block;
  margin: 0 20px;
}
.DateRangePicker__LegendItemColor {
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  margin-right: 6px;
  vertical-align: text-bottom;
  width: 14px;
}
.DateRangePicker__LegendItemColor--selection {
  background-color: #ed5434;
}
.DateRangePicker__PaginationArrow {
  border: 0;
  cursor: pointer;
  display: block;
  height: 20px;
  outline: none;
  padding: 2px;
  position: absolute;
  border-radius: 2px;
  text-align: center;
  top: 10px;
  white-space: nowrap;
  width: 20px;
  background: #2a63ff;
  z-index: 1;
}

.DateRangePicker__PaginationArrow:hover {
  opacity: 0.6;
}
.DateRangePicker__PaginationArrow--previous {
  left: 250px;
  position: absolute;
}
.DateRangePicker__PaginationArrow--next {
  left: 280px;
  position: absolute;
}

.DateRangePicker__PaginationArrowIcon {
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  height: 0;
  top: 10px;
  width: 0;
}
.DateRangePicker__PaginationArrowIcon--is-disabled {
  opacity: 0.25;
}
.DateRangePicker__PaginationArrowIcon--previous {
  border-left: 6px solid transparent;
  border-right: 6px solid white !important;
  margin-left: -2px;
  margin-top: 2px;
}

.DateRangePicker__PaginationArrowIcon--next {
  border-left: 6px solid white !important;
  border-right: 6px solid transparent;
  margin-left: 5px;
  margin-top: 2px;
}

.DateRangePicker__Month {
  color: white;
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 320px;
  padding: 5px;
  background: #323232;
}
.DateRangePicker__MonthHeader {
  color: white;
  background: #323232;
  font-size: 14px;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  position: relative;
  padding-left: 11px;
}
.DateRangePicker__MonthHeaderLabel {
  display: inline-block;
  position: relative;
}
.DateRangePicker__MonthHeaderSelect {
  width: 60px !important;
  background: #323232;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  left: 0;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}
.DateRangePicker__MonthDates {
  background: #323232;
  margin: 0;
  width: 100%;
}
.DateRangePicker__WeekdayHeading,
.DateRangePicker__Date {
  font-size: 12px;
  line-height: 1;
  padding: 10px 0;
  text-align: center;
  width: 14.285714285714286%;
}
.DateRangePicker__WeekdayHeading {
  color: darken(white, 25%);
  border-bottom: 1px solid white;
  font-weight: bold;
}
.DateRangePicker__WeekdayHeading abbr[title] {
  border-bottom-width: 0;
  color: darken(white, 30%);
  cursor: pointer;
  font-size: inherit;
  text-decoration: none;
}
.DateRangePicker__Date {
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.DateRangePicker__Date--otherMonth {
  opacity: 0.25;
}
.DateRangePicker__Date--is-disabled {
  color: #cdcdd1;
  cursor: default;
}
.DateRangePicker__Date--is-selected {
  color: #fff;
}
.DateRangePicker__Date--is-highlighted {
  color: darken(#50c1fb, 30%);
}
.DateRangePicker__CalendarDatePeriod {
  bottom: 0;
  position: absolute;
  top: 0;
}
.DateRangePicker__CalendarDatePeriod--am {
  left: 0;
  right: 50%;
}
.DateRangePicker__CalendarDatePeriod--pm {
  left: 50%;
  right: 0;
}
.DateRangePicker__CalendarSelection {
  background-color: darken(#50c1fb, 5%);
  border: 1px solid darken(#50c1fb, 10%);
  bottom: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 5px;
}
.DateRangePicker__CalendarSelection--inOtherMonth {
  opacity: 0.5;
}
.DateRangePicker__CalendarSelection--start {
  border-right-width: 0;
}
.DateRangePicker__CalendarSelection--end {
  border-left-width: 0;
}
.DateRangePicker__CalendarSelection--segment {
  border-left-width: 0;
  border-right-width: 0;
}
.DateRangePicker__CalendarSelection--single {
  border-radius: 5px;
  left: 5px;
  right: 5px;
}
.DateRangePicker__CalendarSelection--is-pending {
  background-color: #2962ff;
  border-width: 0;
}
.DateRangePicker__CalendarHighlight {
  background-color: darken(#50c1fb, 10%);
  bottom: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 5px;
}
.DateRangePicker__CalendarHighlight--inOtherMonth {
  opacity: 0.5;
}
.DateRangePicker__CalendarHighlight--start {
  border-bottom-left-radius: 5px;
  border-right-width: 0;
  border-top-left-radius: 5px;
  left: 5px;
}
.DateRangePicker__CalendarHighlight--end {
  border-bottom-right-radius: 5px;
  border-left-width: 0;
  border-top-right-radius: 5px;
  right: 5px;
}
.DateRangePicker__CalendarHighlight--segment {
  border-left-width: 0;
  border-right-width: 0;
}
.DateRangePicker__CalendarHighlight--single {
  background-color: rgb(32, 32, 32);
  border-radius: 5px;
  left: 5px;
  right: 5px;
}
.DateRangePicker__HalfDateStates {
  bottom: -50px;
  left: -50px;
  position: absolute;
  right: -50px;
  top: -50px;
  transform: rotate(30deg);
}
.DateRangePicker__FullDateStates {
  background-color: #323232;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.DateRangePicker__DateLabel {
  display: block;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.react-calendar {
  width: 324px !important;
  max-width: 100%;
  background: #323232 !important;
  color: white;
  display: inline-block;
  margin-bottom: 10px;
  padding: 5px 0px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid white !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
  border-radius: 3px;
  font-size: 12px !important;
}

.react-calendar__navigation__label {
  font-size: 14px;
  font-weight: 600;
}

.react-calendar__month-view__weekdays {
  padding-bottom: 3px !important;
  border-bottom: 1px solid white !important;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 12px !important;
  text-transform: none !important;
}

.react-calendar__navigation {
  margin-bottom: 0px !important;
  height: 34px !important;
}

.react-calendar__month-view__weekdays__weekday {
  color: #b3b3b3 !important;
}

.react-calendar__month-view__days__day--weekend {
  color: darken(white, 25%) !important;
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none !important;
}

abbr[title] {
  text-decoration: none !important;
}

.react-calendar__navigation__prev-button {
  position: absolute !important;
  left: 250px !important;
  top: 11px !important;
  width: 22px;
  min-width: 22px !important;
  height: 20px;
  line-height: 0px !important;

  background: #2a63ff !important;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 800;
}

.react-calendar__navigation__label {
  position: absolute !important;
  top: 14px !important;
  left: 20px;
}

.react-calendar__navigation button[disabled] {
  background-color: transparent !important;
}

.react-calendar__navigation__label {
  background-color: transparent !important;
}

.react-calendar__navigation__next-button {
  position: absolute !important;
  left: 280px !important;
  top: 11px !important;
  width: 22px;
  min-width: 22px !important;
  height: 20px;
  line-height: 0px !important;

  background: #2a63ff !important;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 800;
}

.react-datepicker-row > .day:nth-child(1),
.react-datepicker-row > .day:nth-last-child(1) {
  cursor: default;
  color: #5c5e63 !important;
  background: #363a3f !important;
  pointer-events: none;
}
