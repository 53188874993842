.UploadPortfolio {
  margin-bottom: -10px;
  text-transform: uppercase;

  .title {
    color: #2962ff !important;
    font-weight: 600 !important;
    text-transform: uppercase;
    margin-right: 5px;
  }

  .externalTitle {
    color: #2962ff !important;
    font-weight: 600 !important;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .uploadButton {
    margin-top: -10px !important;
  }

  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .inputfile + label {
    cursor: pointer;
    outline: none !important;
    box-shadow: none !important;
    outline-style: none !important;
    background-image: linear-gradient(270deg, #2962ff 0%, #0039cb 100%);
    color: #fff;
    border-radius: 2.55px;
    font-size: 12px;
    font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
    letter-spacing: 1.37px;
    font-weight: 500;
    text-transform: capitalize;
    width: 43% !important;
    height: 28px !important;
    padding: 7px 54px;
    margin: 0px;
  }

  .inputfile:focus + label,
  .inputfile + label:hover {
    background-image: linear-gradient(90deg, #ffffff 0%, #d5d5d5 97%, #d4d4d4 100%);
    border-radius: 3.15px;
    color: #2962ff;
  }
}
