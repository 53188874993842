.base {
  color: #ffffff;
}
.stepContainer {
  display: flex;
  margin: 22px;
  justify-content: space-between;
}

.stepButtons {
  text-transform: uppercase;
  background-color: #3b3f44;
  margin-right: 12px;
  border-radius: 4.05px;
  padding: 9px 11px;
  width: 175px;
  font-size: 11px;
  letter-spacing: 2.1px;
  line-height: 16px;
  text-align: center;
  opacity: 0.61;
}

.selectedStep {
  color: #ffffff;
  background-color: #2962ff;
  opacity: 1;
  font-weight: bold;
}

.bulletPointContainer {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 35px;
  width: 100%;
}

.stepBulletPoint {
  background: white;
  height: 11px;
  border-radius: 50%;
  width: 11px;
  margin-right: 10px;
}

.selectedBulletPoint {
  background: #2962ff;
}
