.Currency .builderSwitchComponentContainer {
  padding: 8px 0px 0px 10px;
}

.Currency .inputrow {
  align-items: center;
  height: 35px;
}

.Currency .inputrow input {
  margin-top: 0px !important;
}

.Currency .inputrow label {
  right: 45px;
  margin-top: -7px;
}
.Currency .builderInputComponentContainer span {
  width: 80px;
}

.Currency .builderInputComponentContainer {
  margin-bottom: 0px !important;
}
.Currency .builderRatioButtonsOptions {
  align-items: center !important;
}

.Currency > div:nth-child(1) {
  width: 100% !important;
}

.Currency > div:nth-child(3) {
  display: flex !important;
}

.Currency > div:nth-child(3) > div {
  display: flex !important;
  margin-left: 20px;
}

.Currency > div:nth-child(3) > div > div:nth-child(3) > div {
  width: 220px;
}

.Currency > div:nth-child(4) {
  width: 100%;
}

.titleInput {
  margin-right: 20px;
}

.Currency > div:nth-child(3) > div > div:nth-child(3) > div input {
  width: 80px;
}

.Currency .builderRatioButtonsContainer {
  margin-top: -7px;
}

.Risk .titleInput {
  width: 125px;
  margin-right: 20px;
}

.Risk .builderInputComponentContainer > div {
  margin-left: 0px;
}

.Risk > div:nth-child(2) > div > div {
  margin-left: 0px;
}

.Risk .inputrow {
  margin-top: 5px;
}

.Weighting .radioHolder {
  width: 250px;
}

.Weighting .addibleRows {
  width: 100%;
}

.Weighting .addibleRows .dropdownLine > span {
  height: 35px;
  padding-top: 4px !important;
}

.Weighting .addibleRows > div {
  flex-direction: column;
  align-items: flex-start;
}

.Weighting .builderInputComponentContainer > div {
  margin-left: 0px;
  width: 100%;
}
.Weighting .builderInputComponentContainer > div > div,
.Weighting .builderInputComponentContainer > div > div > div {
  width: 100%;
  justify-content: space-between;
}

.Weighting .addibleRows > div > div:nth-child(1) > .dropdownField {
  display: flex;
  align-items: center;
}
.Weighting .addibleRows > div > div:nth-child(1) > .dropdownField > div {
  margin-left: 10px;
  width: 80px !important;
}

.Weighting .structureItem:nth-child(1) > div {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.Weighting .structureItem:nth-child(1) > div > div {
  margin-right: 10px;
}
.Weighting .structureItem {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Weighting .structureItem input {
  width: 100px;
}

.Weighting .structureItem {
  text-align: right;
}

.Weighting .structureItem > div {
  width: 100%;
}

.Weighting .vertical {
  width: 300px !important;
  text-transform: uppercase;
}

.Weighting .addibleRow .dropdown__menu {
  max-width: 80px !important;
  min-width: 80px !important;
}

.Weighting
  .Rebalancing
  > div
  > div
  .optimizationSwitchContent
  ~ .optimizationSwitchContent
  .dropdownTitle {
  display: none;
}

.Rebalancing
  > div
  > div
  .optimizationSwitchContent
  ~ .optimizationSwitchContent
  ~ .optimizationSwitchContent
  ~ .optimizationSwitchContent
  .dropdownTitle {
  display: block !important;
}

.Rebalancing .builderRadioGroup {
  align-items: flex-end;
}

.settings .dropdownLine {
  display: contents;
}

.dataUpload .dropdownLine > div {
  width: 150px !important;
}

.dataUpload .dropdownLine .dropdownLine > div {
  width: 110px !important;
}

.buttonSettingsTemplate {
  width: 31.5% !important;
}

.tooltipDropdown {
  display: none;
  position: absolute;
  width: 370px !important;
  left: 0px !important;
  margin-top: -75px;
  margin-left: 130px;
  border-radius: 5px;
  background-color: #f7f7f7;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
  padding: 3px 8px;
  color: #333333;
}
div:hover ~ .tooltipDropdown {
  display: block;
  color: #333333;
  background: #f7f7f7;
  opacity: 0.85;
}
