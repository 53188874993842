@import '../../sass/Variables.scss';

.closeModal {
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 0px;
  right: 10px;
  color: $text-color-main;
}

.separator {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  min-width: 400px;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.transitionModal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  transition: opacity 0.15s; /* Opacity will fade smoothly */
}
.transitionModal--invisible {
  opacity: 0;
  pointer-events: none; /* Makes the modal unclickable */
}

.transitionModal--visible {
  opacity: 1; /* Modal is visible */
  pointer-events: initial; /* Modal is clickable */
}

.downloadHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modalTitle {
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #2962ff;
  font-size: 14px;
}
.radioButtonsContainer {
  display: flex;
  justify-content: space-between;
  height: 20px;
  padding: 0px 5px;
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-weight: 600;
    font-size: 13px;
    color: white;
    margin-right: 10px;
  }
}
.radioButtonsESGContainer {
  display: flex;
  height: 20px;
  padding: 0px 5px;
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-weight: 600;
    font-size: 13px;
    color: white;
    margin-right: 10px;
  }
}

.modalText {
  font-weight: 200;
}
.buttonsFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px !important;
  margin-top: 30px;
}

.closeDownloadModal {
  font-size: 30px;
  cursor: pointer;
  font-weight: 300;
  display: block;
  position: absolute;
  top: -20px;
  right: -5px;
  padding: 20px;
}
