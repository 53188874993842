.pageContainer {
  border-radius: 8px;
  background: #2a2d31;
  height: 100%;
  padding: 20px;
}
.builderHorizontalContainer {
  display: flex;
  flex-wrap: wrap;
}
.widgetTitle {
  width: 50%;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
  text-transform: uppercase;
  svg,
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.widgetDescription {
  width: 100%;
  font-size: 13px;
  letter-spacing: 1.48px;
  line-height: 19px;
  text-align: justify;
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px solid hsla(0, 0%, 100%, 0.4);
}

.widgetHeaderContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}
.widgetSwitchComponentContainer {
  display: flex;
  position: relative;
  flex-flow: column;
  background: #3b3f44 !important;
  padding: 18px;
  border-radius: 4.05px;
  margin-top: 5px;
  width: 100%;
}
.titleComponentContainer {
  display: flex;
  position: relative;
  flex-flow: column;
  background: #3b3f44 !important;
  padding: 18px;
  border-radius: 8px;
  margin-top: 36px;
  width: 100%;
  font-size: 30px;
  h2 {
    display: flex;
    align-items: center;
    font-size: 16px !important;
    span {
      margin-left: 10px;
    }
  }
  svg {
    width: 19px !important;
    height: 17px !important;
  }
}

.widgetTitle {
  width: 50%;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
  text-transform: uppercase;
}

.defaultPageTitle {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.4px;
  margin: 0;
  display: flex;
  align-items: center;
}

.defaultPageTitle > svg {
  margin-right: 15px;
  width: 36px;
  height: 36px;
}

.builderRadioGroup {
  padding: 0px 15px;
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
}

.structuredWidget {
  padding: 0px 15px;
  padding-left: 0px;
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end !important;
}

.widgetContent {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 15px 35px 0px;
}

.builderRadioGroup > * {
  margin-bottom: 6px;
}

.defaultPageTitle span {
  background: #fff;
  color: #0039cb;
  border-radius: 6px;
  padding: 4px 6px;
  margin-right: 4px;
  display: inline-block;
  font-weight: bold;
  height: auto;
}

.pageContainer button {
  /* flex-grow: 1; */
}

.builderStep {
  border-radius: 5px;
  height: 100%;
  background: #3b3f44;
  margin: 20px;
  padding-bottom: 150px;
}

.stepTitle {
  padding: 21px 15px;
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2.42px;
  text-align: justify;
}

.builderStepHeader {
  display: flex;
  justify-content: space-between;
}

.stepSubtitle {
  padding: 0 15px;
  font-size: 15px;
  letter-spacing: 1.71px;
}

.stepNavigation {
  margin-top: 20px;
}

.stepNavigationButton {
  width: 120px !important;
  height: 35px;
  margin-right: 20px;
  visibility: hidden;
}

.stepNavigationButtonVisible {
  visibility: visible !important;
}

.widgetInput ~ label {
  top: 15px;
}
.widgetInput {
  background-color: unset;
  background: #4b5158 !important;
  height: 30px;
  width: 110px;
  font-size: 13px;
  height: 35px;
  margin-top: 7px;
  padding-left: 5px;
  line-height: 25px;
  overflow: hidden;
}

.widgetTransparentInput {
  background-color: unset;
  background: transparent !important;
  height: 30px;
  width: 100px;
  font-size: 13px;
  height: 35px;
  width: 100px;
  margin-top: 7px;
  padding-left: 5px;
  line-height: 25px;
  overflow: hidden;
}

.stepTitle svg {
  border: 1px solid #fff;
  padding: 1px;
  border-radius: 3px;
  width: 17px;
  height: 17px;
}

.stepTitle span {
  margin-left: 15px;
}

/* Optimization component type card */
.cardOptimizationComponentContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.builderCard {
  margin: 30px 30px 0 0 !important;
  cursor: pointer;
  .title {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 2.42px;
    line-height: 19px;
    text-align: justify;
  }
}

.builderSwitchComponentContainer {
  display: flex;
  position: relative;
  justify-content: center;
  flex-flow: row;
  padding: 14px;
  border-radius: 8px;
  width: 100%;
}
.builderSwitchComponentContainer > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.builderTitleComponentContainer {
  display: flex;
  position: relative;
  flex-flow: column;
  background: #4b5158;
  padding: 14px;
  border-radius: 8px;
  margin-top: 36px;
  width: 100%;
  font-size: 30px;
}

.builderSwitchContent {
  /*
    min-height: 15px;
    padding: 10px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    */
}

.builderWidgetTitle {
  width: 50%;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
  text-transform: uppercase;
}

.builderWidgetHeaderContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 15px;
}

.builderSwitchComponentTop {
  width: 300px;
  display: flex;
  justify-content: space-between;
}

.addibleRow {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 15px;
  > div {
    width: auto;
    margin-right: 10px;
  }
}

.addibleRow > div:first-child {
  padding-left: 0 !important;
}

.addibleRowRemoveButton {
  width: 35px !important;
  height: 35px !important;
  background: #fff;
  color: #2962ff;
  border: 0;
  font-weight: 700;
}

.addibleRowPlusButton {
  width: 35px !important;
  height: 35px !important;
  background: white;
  margin-top: 5px;
  color: #2962ff;
  border: 0;
  font-weight: bold;
}
.tab {
  padding: 0;
}
.tabHeading {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.4);
}

.builderCardSetContainer ~ div ~ .tabHeading {
  border-bottom: none;
}

.tabHeading svg,
.tabHeading img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.builderInputComponentContainer {
  display: flex;
  // width: 200px;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
}

.builderInputContainer {
  margin-left: 15px;
  display: flex;
}

.dropdownText {
  padding-top: 10px;
  margin-right: 10px;
}

.dropdownTextCenter {
  margin-right: 10px;
  justify-content: center;
}

.dropdownPostText {
  padding-top: 10px;
  margin-right: 10px;
}

.dropdownLine {
  display: flex;
  //width: 100%;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 0px !important;
}

.dropdownField {
  padding: 0px;
  margin-right: 15px;
}

.addibleRow .dropdownContainer {
  margin-bottom: 10px !important;
}

.builderSelect {
  width: 100%;
}

.applyDeleteContainer {
  text-align: right;
  position: absolute;
  bottom: 15px;
  right: 0;
  padding-top: 20px;
}

.builderInfoSquare {
  height: 27px;
  width: 80px;
  border: 1px solid #2962ff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.builderRatioButtonsContainer {
  display: flex;
}
.builderRatioButtonsOptions {
  display: flex;
  input[type='checkbox']:checked,
  input[type='checkbox']:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type='checkbox']:checked + label,
  [type='checkbox']:not(:checked) + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-weight: 600;
    font-size: 13px;
    color: white;
    margin-right: 15px;
  }
  [type='checkbox']:checked + label:before,
  [type='checkbox']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 17px;
    height: 17px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: transparent;
  }

  [type='checkbox']:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 17px;
    height: 17px;
    border: 1px solid #2962ff;
    border-radius: 100%;
    background: transparent;
  }
  [type='checkbox']:checked + label:after,
  [type='checkbox']:not(:checked) + label:after {
    content: '';
    width: 9px;
    height: 9px;
    background: #2962ff;
    position: absolute;
    top: 6px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type='checkbox']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.radioParentGroup {
  display: flex;
  flex-flow: column;
}

.applyDeleteButton {
  margin-right: 10px;
}

.builderCardSetContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.builderCard {
  display: block;
  padding: 20px;
  width: 30%;
  margin: 1.2% !important;
  max-width: 390px;
  min-width: 210px;
  height: 140px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.customRadio {
  display: inline-block;
  font-weight: 500;
  margin-right: 40px;
}

.radioHolder {
  display: flex;
  width: 200px;
  font-weight: bold;
  justify-content: space-between;
}
.radiofilterHolder {
  display: flex;
  width: 200px;
  font-weight: bold;
}

.radioHolder span {
  min-width: 120px;
  text-transform: uppercase;
  font-family: 'Neue Haas Grotesk Display Pro', serif;
}

.inputLabelAfter {
  font-weight: bold;
  margin: 0 10px;
}

.topHelpers {
  display: flex;
  padding: 10px 0;
}

.topHelpers > div {
  display: flex;
  margin-left: 12px;
  margin-right: 12px;
}

.topHelpers > div span {
  display: contents;
  margin-left: 0;
}

.builderMultiSelectContainer {
  width: 100%;
  margin: 10px 0;
  color: #fff !important;
}

.builderMultiSelect {
  padding: 10px;
  color: #fff !important;
}

.builderButtonComponent {
  width: auto !important;
  padding: 0 14px;
}

.builderMultiSelectLabel {
  display: none;
}

/* =========== */
.searchTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchFilterOptions {
  text-align: left;
  display: flex;
}

.searchInput {
  text-align: left;
  width: 500px;
  margin-top: 5px;
}

.searchResultsContainer {
  display: block;
  font-size: 14px;
}

.searchResultColumn {
  color: #fff;
}

.searchResultColumn:first-child {
  width: 45%;
}

.searchResultColumn:nth-child(2) {
  width: 35%;
}

.searchResultColumn:nth-child(3) {
  width: 20%;
}

.searchResult {
  border-radius: 6px;
  background: #4b5158;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchResult b {
  display: inline-block;
  margin-right: 10px;
}

.deleteResult {
  position: static !important;
  width: auto !important;
  font-weight: bold;
  padding: 4px 16px;
}

.addibelRowsStyle {
  display: flex;
}

.titleTextComponent {
  width: 100%;
  color: #2962ff !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  margin-bottom: 8px;
}

.backtest_status_message {
  width: 100%;
  padding: 30px 40px 0 40px;
  text-align: center;
  height: auto !important;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .addibleRow .dropdownLine .dropdownLine .dropdownLine {
    width: 100%;
  }
}

.disclaimer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.disclaimer_row {
  display: flex;
  flex-direction: row;
  flex: 1;
}
