.contentUniverseBuilder .builderSwitch {
  display: flex;
}
.contentUniverseBuilder .builderSwitch .BitaSwitch {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.builderWidget:nth-last-child(1) > div:nth-last-child(1) > div:nth-last-child(2) {
  padding: 10px 15px 180px 0px;
}

.ReactModalPortal .builderWidget:nth-last-child(1) > div:nth-last-child(1) > div:nth-last-child(2) {
  padding: 10px 15px 90px 0px;
}

.ReactModalPortal .Rebalancing .dropdown__menu {
  height: 130px;
}

.tabHeadingFilter {
  border-bottom: none;
  text-transform: uppercase;
}

.customRadio {
  display: inline;
}

.Size .subradioholder {
  margin-left: 50px;
}

.Sectors ~ div {
  align-items: flex-start;
}

.Geography ~ div .BitaSwitch {
  margin-left: 10px;
}
.Geography ~ div .builderSwitchComponentContainer {
  flex-wrap: wrap;
}
.Geography ~ div .topHelpers {
  flex-wrap: wrap;
}

.Geography ~ div .dropdownLine {
  width: 100%;
}

.Geography ~ div .dropdownLine .builderToggleGroup {
  width: 100%;
}

.Geography ~ div .builderToggleGroup .builderRadioGroup .dropdownLine .dropdownField {
  width: 135px;
}
.Geography
  ~ div
  .builderToggleGroup
  .builderRadioGroup
  .dropdownLine
  .dropdownField
  ~ .dropdownLine {
  width: 200px;
}

.Geography ~ div .radioHolder > div:nth-child(1) {
  margin-top: -50px;
  margin-left: 370px;
}

.Geography ~ div .radioHolder ~ .builderToggleGroup .builderMultiSelectContainer {
  width: 100%;
}

.Sectors ~ .dropdownLine > .dropdownLine,
.Sectors ~ .dropdownLine > .dropdownLine > .dropdownLine,
.Geography ~ .addibleRows > .addibleRow > .dropdownLine > .dropdownLine,
.Issues ~ .dropdownLine > .dropdownLine,
.Themes ~ .dropdownLine > .dropdownLine {
  margin-top: -40px;
  flex-direction: column !important;
  align-items: flex-start;
  width: 100%;
}

.Geography ~ .addibleRows .radioHolder {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Geography ~ div .topHelpers {
  justify-content: space-between;
}

.Geography ~ .addibleRows .topHelpers .radiofilterHolder {
  width: 100%;
}

.Themes ~ div .searchInput {
  width: 300px !important;
}

.Sectors ~ .dropdownLine > .dropdownLine > .dropdownField,
.Geography ~ .addibleRows > .addibleRow > .dropdownLine > .dropdownLine > .dropdownField,
.Issues ~ .dropdownLine > .dropdownLine > .dropdownField,
.Themes ~ .dropdownLine > .dropdownLine > .dropdownField {
  margin-left: 220px;
  margin-top: -5px;
}
/* 
 .Geography ~ .addibleRows .dropdownLine > .dropdownLine > .dropdownLine > .dropdownField
 {
  margin-left: 0px;
  margin-top: 30px;
} */

.Sectors ~ .dropdownLine > .dropdownLine > .dropdownLine,
.Geography ~ .dropdownLine > .dropdownLine > .dropdownLine {
  width: 100%;
}

.Sectors ~ .dropdownLine > .dropdownLine > .dropdownLine > .dropdownField,
.Geography ~ .dropdownLine > .dropdownLine > .dropdownLine > .dropdownField {
  margin-left: 440px;
  margin-top: -15px;
}
.Geography ~ .addibleRows .builderButtonComponent {
  margin-left: 10px;
}
.Sectors ~ .dropdownLine .builderMultiSelectContainer {
  position: relative;
}

.Size .builderInputComponentContainer {
  justify-content: flex-start;
}
.Single ~ div > div:nth-child(2) .searchResultColumn .searchResultColumn {
  line-height: 22px;
}

.Single ~ div .builderSearchColumn > .searchResultColumn:nth-child(1) {
  width: 45% !important;
}

.Size ~ div > div:nth-child(4) .radioHolder {
  margin-bottom: 10px;
}

.Size ~ div > div .radioHolder span {
  min-width: 120px;
  text-transform: capitalize;
  font-family: 'Neue Haas Grotesk Display Pro', serif;
}

.Size ~ div .builderInputComponentContainer {
  justify-content: flex-start !important;
}

.Size ~ div .builderInputComponentContainer > div {
  margin-left: 0px !important;
}
.Factors ~ div > .addibleRow,
.Liquidity ~ div > .addibleRow,
.Fundamentals ~ div > .addibleRow,
.Ratings ~ div > .addibleRow {
  align-items: flex-start;
}

.Factors ~ div > .addibleRow input,
.Liquidity ~ div > .addibleRow input,
.Fundamentals ~ div > .addibleRow input,
.Ratings ~ div > .addibleRow input,
.Size ~ div input {
  margin-top: 0px;
  margin-bottom: 5px !important;
}

.Factors ~ div > .addibleRow > div:nth-child(2) {
  flex-wrap: wrap;
  align-items: flex-start;
}

.Factors ~ div > .addibleRow .dropdownContainer,
.Liquidity ~ div > .addibleRow .dropdownContainer,
.Fundamentals ~ div > .addibleRow .dropdownContainer,
.Ratings ~ div > .addibleRow .dropdownContainer,
.Size ~ div .dropdownContainer {
  margin-bottom: 10px !important ;
}

.Factors ~ div > .addibleRow > div:nth-child(2) input {
  margin-top: 0;
}

.Factors ~ div > .addibleRow > div:nth-child(2) .builderInputComponentContainer {
  margin-bottom: 10px;
}

.Factors ~ div > .addibleRow .dropdownField {
  padding-bottom: 0px;
}

.Factors ~ div > .addibleRow .builderInputComponentContainer > div {
  margin-left: 0px;
}

.Factors ~ div > .addibleRow .inputPostText {
  padding-top: 0px;
}

.Liquidity ~ div > .addibleRow .inputPostText {
  padding-top: 5px;
}

.Cash .builderInputComponentContainer > div {
  margin-left: 0px;
}

.Deletion .radioHolder {
  width: 250px;
}
.Deletion .builderToggleGroup .builderToggleGroup {
  flex-wrap: wrap;
}

.Deletion .builderToggleGroup .builderToggleGroup div:nth-child(2) .radioHolder {
  width: 300px;
  margin-left: 20px;
}

.Deletion .radioHolder ~ div > div:nth-child(2) .radioHolder {
  width: 300px;
  margin-left: 20px;
}

.Deletion .radioHolder ~ div .radioHolder span {
  text-transform: initial;
  font-weight: normal;
}

.Securities .radioHolder {
  width: 320px;
}

.Securities > div:nth-child(2) {
  flex-direction: column;
}

.Securities .builderInputComponentContainer > div {
  margin-left: 0px;
}

.Securities .builderInputComponentContainer span {
  padding-top: 0px;
  text-transform: uppercase;
}

.Securities .dropdownLine > span {
  padding-top: 0px !important;
}

.Securities .dropdownField {
  padding-bottom: 0px !important;
}

.Global .dropdownField > div > div {
  margin-top: 20px;
}

.Global input {
  margin-right: 15px;
}

.Return .builderInputComponentContainer {
  margin-bottom: 0px !important;
  margin-left: 10px;
}

.Return .dropdownField {
  margin-right: 0px !important;
  padding-bottom: 0px !important;
}

.Return .dropdownLine {
  align-items: flex-end;
}

.Number .dropdownField > div {
  width: 150px !important;
}

.Number .builderHorizontalContainer {
  flex-direction: column;
}

.Number .id-rank_by_buffer,
.Number .id-rank_by_buffer .dropdownLine {
  width: 100%;
}

.Number .id-rank_by_buffer .dropdownField > div {
  width: 120px !important;
  margin-top: -40px;
  margin-left: 160px;
}

.Number .id-rank_by_buffer .dropdownLine .dropdownField > div {
  width: 160px !important;
  margin-top: -50px;
  margin-left: 290px;
}

.Number .id-rank_by_buffer .dropdownLine .dropdownLine > .dropdownField > div {
  width: 120px !important;
  margin-top: -60px;
  margin-left: 460px;
}

.Number .id-rank_by_buffer .dropdownLine .dropdownLine .dropdownLine > .dropdownField > div {
  width: 120px !important;
  margin-top: -75px;
  margin-left: 590px;
}

.Number .id-ranking_type .builderToggleGroup {
  width: 100%;
  margin-top: -10px;
}
.Number .id-ranking_type .builderToggleGroup .builderRadioGroup {
  margin-top: 10px;
}
.Number .dropdownLine .dropdownLine input {
  margin-top: -2px;
}

.Number .dropdownLine .dropdownLine .dropdownLine .dropdownLine .dropdownLine .dropdownField {
  width: 100%;
}

.Number .dropdownLine .dropdownLine .inputrow span {
  margin-right: 10px;
}
.Number .builderInputComponentContainer {
  margin-bottom: 0px !important;
}

.Caps .addibleRow {
  margin-bottom: 10px;
  margin-top: 0px;
  align-items: start;
}

.Caps .builderToggleGroup > div {
  align-items: start !important;
}

.Caps .inputStyle {
  margin-top: 0px;
}

.Caps .radioHolder {
  margin-top: 6px;
  width: 190px !important;
}

.Caps .dropdownLine {
  align-items: start;
}

.Caps .addibleRow .dropdownField {
  padding-bottom: 0px !important;
}

.Caps .addibleRow input {
  width: 80px !important;
}

.Reconstitution > div:nth-child(2) {
  flex-direction: column;
}

.Reconstitution > div:nth-child(2) > div:nth-child(2) {
  padding-top: 0px !important;
}

.Rebalancing .builderRadioGroup,
.Reconstitution .builderRadioGroup {
  align-items: flex-end;
}

.Rebalancing .builderRadioGroup > .dropdownLine:nth-child(1) > span,
.Reconstitution .builderRadioGroup > .dropdownLine:nth-child(1) > span {
  padding-top: 0px !important;
}
.Rebalancing .radioHolder,
.Reconstitution .radioHolder {
  width: 250px;
}

.Entry .dropdownField,
.exit .dropdownField {
  margin-bottom: 10px;
}

.Entry .radioHolder,
.exit .radioHolder {
  width: 250px;
}
.Entry .radioHolder ~ .builderRadioGroup,
.exit .radioHolder ~ .builderRadioGroup {
  margin-bottom: 10px;
}
.Entry .radioHolder ~ .builderRadioGroup .radioHolder,
.exit .radioHolder ~ .builderRadioGroup .radioHolder {
  width: 350px;
  margin-left: 0px;
}
.Entry .radioHolder ~ .builderRadioGroup .radioHolder span {
  font-weight: 300;
  text-transform: initial;
}

.Entry .builderInputComponentContainer,
.exit .builderInputComponentContainer {
  margin-bottom: 15px;
}

.Currency .builderToggleGroup > div {
  display: flex;
  align-items: center;
}
.Currency .builderToggleGroup > div .radioHolder {
  width: 140px;
  margin-right: 60px;
  padding-top: 2px;
}

.Weighting > div:nth-child(2) {
  flex-direction: column;
}
.Weighting > div:nth-child(2) > div:nth-child(2) {
  padding-top: 0px !important;
}

.Currency .builderToggleGroup > div .radioHolder span {
  width: 80px;
}
.Currency .builderToggleGroup > div .inputrow span {
  margin-top: -5px;
}

.addiblerowdisabled {
  height: 0px;
  width: 0px;
  margin: 0px;
  overflow: hidden;
  margin-bottom: 0px !important;
}

.selection-list-container .dropdownField {
  margin-bottom: 10px;
}
.selection-list-container .inputStyle {
  margin-top: -2px !important;
}

@media (max-width: 1544px) {
  .Factors ~ div > .addibleRow > div:nth-child(2) {
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
    margin-top: -10px;
  }
  .Factors ~ div > .addibleRow > div:nth-child(2) > .dropdownLine {
    position: relative;
    position: relative;
    left: -225px;
  }
  .Factors ~ div > .addibleRow > div:nth-child(2) > .dropdownLine > .dropdownField {
    margin-right: 25px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1240px) {
  .Number .id-rank_by_buffer .dropdownLine .dropdownField > div {
    width: 160px !important;
    margin-top: -50px;
    margin-left: 290px;
  }

  .Number .id-rank_by_buffer .dropdownLine .dropdownLine > .dropdownField > div {
    width: 160px !important;
    margin-top: 0px;
    margin-left: 0px;
  }

  .Number .id-rank_by_buffer .dropdownLine .dropdownLine .dropdownLine > .dropdownField > div {
    width: 140px !important;
    margin-top: -40px;
    margin-left: 170px;
  }

  .Number .id-ranking_type .builderToggleGroup {
    margin-top: 5px;
  }

  .Number .builderToggleGroup {
    margin-top: 5px;
  }
  .Number .id-ranking_type .builderToggleGroup .builderRadioGroup {
    margin-top: 10px;
  }
}
