.bita_ws_connection_widget {
  @media (max-width: 1325px) {
    right: 10px;
  }

  width: 12px;
  height: 12px;
  background-color: red;
  border: solid 1px #1d1d1d;
  border-radius: 100px;
  position: relative;
  right: 8px;
  top: 2px;

  p.ugly-fix {
    color: #ffffff00;
  }
}
