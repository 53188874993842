.videosItem {
  display: flex;
  height: 100px;
}

.videosImage {
  width: 168px;
  height: 94px;
  margin: 4px;
}

.videosDescription {
  width: calc(100% - 200px);
  font-size: 12px;
  margin: 4px;
  display: flex;
  flex-direction: column;
  > p:nth-child(1) {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 15px;
  }
  > div {
    color: #aaa;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
