.base {
  padding: 10px;
  border-radius: 8px;
  background-color: #2a2d31;
  height: 100%;
}
.contentContainer {
}

.firstHalfContainer {
  height: 50%;
  display: flex;
  justify-content: space-between;
}

.halfWidthContainer {
  width: 50%;
  justify-content: space-between;
  display: flex;
  flex-flow: column;
}
.benchmarkSearchDropdown {
  width: 100%;
  margin-right: 10px;
  height: 36px;
}

.baseInfoContainer {
  display: flex;
  margin: 3px 10px 3px 5px;
  height: 70%;
  border-radius: 5px;
  background-color: #3b3f44;
  flex-flow: column;
}

.baseInfoTitleContainer {
  padding: 20px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
}

.baseInfoTitle {
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }
}
.baseTitle {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.78px;
  line-height: 14px;
  color: #2962ff;
}

.baseDescription {
  font-size: 12px;
  letter-spacing: 1.94px;
  line-height: 15px;
}

.baseNamesContainer {
  display: flex;
  flex-flow: column;
}

.baseContainer {
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
  margin-left: 20px;
}

.benchmarkContainer {
  padding: 20px;
  width: 100%;
  flex-flow: column;
  margin-top: auto;
  display: flex;
}

.benchmarkTitle {
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 5px;
  letter-spacing: 1.78px;
  line-height: 14px;
  color: #2962ff;
  text-transform: uppercase;
}

.benchmarkSearch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.downloadContainer {
  display: flex;
  margin: 3px 10px 3px 5px;
  border-radius: 5px;
  background-color: #3b3f44;
  height: 30%;
  flex-flow: column;
  padding: 20px;
  justify-content: center;
}

.dateContainer {
  width: calc(100% - 5px);
  display: flex;
  align-items: center;
  margin: 3px 10px 3px 5px;
  border-radius: 5px;
  background-color: #3b3f44;
  height: 60px;
  padding: 20px;
}

.dropdownContainer {
  width: 95% !important;
}

.benchmarkSearchDropdown {
  width: 100%;
  height: 36px;
}

.downloadTitle {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
}

.downloadButtonsContainer {
  display: flex;
  flex-wrap: wrap;
}

.generalStatsContainer {
  margin: 3px 0px;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  background-color: #3b3f44;
}

.generalTitleContainer {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-transform: uppercase;
  text-align: justify;
  display: flex;
  justify-content: space-between;
}

.bottomHalfContainer {
  width: 100%;
  height: 50%;
  display: flex;
}

.constituentsContainer {
  margin: 3px 5px;
  width: 50%;
  padding: 20px;
  border-radius: 5px;
  background-color: #3b3f44;
}

.constituentsTitleContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.constituentsTitle {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-transform: uppercase;
  text-align: justify;
}

.sectorTitle {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;

  text-transform: uppercase;
}

.tableContent {
  max-height: 100%;
  width: 100%;
  overflow: auto;
}

.constituentsTableContainer {
  height: 90%;
}

.weightsChartContainer {
  border-radius: 5px;
  background-color: #4b5158;
  margin-top: 10px;
  max-height: 250px;
  position: relative;
}

.sectorsChartContainer {
  border-radius: 5px;
  background-color: #4b5158;
  margin-top: 10px;
  height: 200px;
  display: flex;
  align-items: center;
}

.sectorAndWeightsContainer {
  margin: 3px;
  width: 51%;
  max-height: 100%;
  padding: 20px;
  border-radius: 5px;
  background-color: #3b3f44;
}

.tabStyle {
  cursor: pointer;
  margin-right: 9px;
  height: 33px;
  width: 184px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 1.48px;
  line-height: 20px;
  text-align: center;
  border-radius: 3.15px;
  background-color: #4b5158;
}

.performanceTitleContainer {
  margin-bottom: 10px;
}

.performanceTitle {
  font-size: 15px;
  /* margin-bottom: 21px; */
  font-weight: bold;
  letter-spacing: 2.42px;
  line-height: 19px;
  text-align: justify;
}

.performanceButtonsContainer {
  display: flex;
  justify-content: space-between;
}

.performanceTabs {
  display: flex;
}

.performanceChartContainer {
  border-radius: 5px;
  background-color: #4b5158;
  height: 78%;
  margin-top: 16px;
}

.bitaButtonCustom {
  margin: 0px 5px 5px 0px;
}

.radioButtonChart {
  box-sizing: border-box;
  height: 14px;
  width: 14px;
  border: 1px solid #ffffff;
  border-radius: 9px;
}

.radioButtonChartSelected {
  box-sizing: border-box;
  height: 14px;
  width: 14px;
  border: 1px solid #2962ff;
  border-radius: 9px;
}

.radioButtonsSelected {
  display: flex;
  justify-content: space-between;
}
.radioButton {
  display: flex;
}

.radioButtonsContainer {
  display: flex;
  justify-content: space-between;
  padding: 15px 5px 0px;
}

.radioButtonChartSelectedPoint {
  box-sizing: border-box;
  height: 5.25px;
  width: 5.25px;
  border: 1px solid #2962ff;
  border-radius: 3.38px;
  position: absolute;
  background-color: #2962ff;
}

.radioButtonChartContainer {
  cursor: pointer;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.radioButtonName {
  margin-left: 5px;
}

.generatedTable {
  margin-bottom: 10px;
  width: 100%;
  border-radius: 5px;
}

.tableTbody {
  background-color: #4b5158;
}

.tableHeaderRow {
  font-size: 11.3px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 22px;
  text-align: justify;
  background-color: #3b3f44;
}

.tableHeaderContainer {
  background-color: #3b3f44;
  border-bottom: 1px solid #2962ff;
}

.generatedTableHeader {
  border-bottom: 1px solid;
}

.restTableHeaders {
  border-left: 1px solid;
  text-align: left;
  padding: 5px;
}

.firstTableHeader {
  padding: 5px;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 2.1px;
  line-height: 16px;
  text-align: justify;
  color: #2962ff;
}
.firstTableValue {
  color: #ffffff;
  /* font-family: "Neue Haas Grotesk Text Pro"; */
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
}

.tableValueRow {
  height: 40px;
  border-bottom: 1px solid;
}
.tableValue {
}

.generatedTableValues {
  border-bottom: 1px solid;
}
.restTableValues {
  padding: 5px;
  border-left: 1px solid;
  text-align: left;
}

.tableContainer {
  border-radius: 5px;
  background-color: #4b5158;
  height: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.tableContainerGenerated {
  border-radius: 5px;
  max-height: 400px;
  height: 100%;
  margin-bottom: 10px;
  td {
    width: 15% !important;
    div {
      max-width: 210px;
    }
  }
  th:nth-child(1) {
    width: 150px !important;
    padding-left: 30px;
  }
  td:nth-child(1) {
    width: 300px !important;
    padding-left: 30px;

    span {
      max-width: 300px !important;
    }
    div {
      max-width: 300px !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    span:hover {
      background: transparent !important;
      width: initial;
      position: initial;
      white-space: rap;
      text-overflow: unset;
      overflow: unset;
      display: flex;
      align-items: center;
      margin-left: 0px;
      margin-right: 0px;
      padding-top: 0px;
      padding-right: 0px;
      margin-top: 0px;
    }
  }
}

.generalCharts {
  overflow: auto;
}

.listContainer {
  width: 49%;
  display: flex;
  justify-content: space-around;
  padding: 15px;
  border-radius: 4.05px;
  margin: 20px 0px;
  background-color: #4b5158;
}

.listContent {
  margin-right: 20px;
}

.listTitleContainer {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 16px;
  color: #2962ff;
  border-bottom: 1px #2962ff solid;
  padding-bottom: 4px;
}

.listValue {
  display: flex;
  border-bottom: 1px solid;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: space-around;

  span {
    width: 40%;
  }
}

.leftText {
  text-align: left;
}

.rightText {
  text-align: right;
}

.oneValue {
  width: 100% !important;
  text-align: center;
}

@media (max-width: 768px) {
  .base {
    padding: 20px;
  }

  .firstHalfContainer,
  .bottomHalfContainer {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 100%;
      margin: 0px;
      margin-bottom: 12px;
    }
  }
  .constituentsTitle {
    font-size: 13px;
  }
  .baseInfoContainer,
  .dateContainer {
    margin: 0px;
    width: 100%;
    margin-bottom: 12px;
  }
  .downloadContainer {
    margin: 0px;
  }
  .generalTitleContainer button {
    top: -645px !important;
  }
}
